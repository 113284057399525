import { Tooltip as MuiTooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AreaChart, CartesianGrid, ResponsiveContainer } from "recharts";
import { graph } from "../Constants";
import { formatLabel } from "../utils/helper";

const BigNumberTotal = ({ itemData }: any) => {
  const { chartData: data, title, meta_data } = itemData;
  const config = graph.area;
  const [firstKey] = Object.keys(data?.[0]);
  const getSymbol = () => {
    return meta_data?.currency_format?.symbol === "USD" ? "$" : "";
  };

  return (
    <Box className='bigNumber pageGraph'>
      <MuiTooltip title={title} placement='top-start'>
        <Typography variant='h5semibold' className='heading'>
          {title}
        </Typography>
      </MuiTooltip>
      <ResponsiveContainer width={config.width} height={config.height} className='noxyAxsis'>
        <AreaChart data={data} margin={{ top: 210, right: 0, left: 10, bottom: 40 }}>
          {config.showGrid && <CartesianGrid strokeDasharray='3 3' />}
          <svg>
            <text x='30' y='190' textAnchor='start' fontSize={config.bigNumberSize} fill='black'>
              {getSymbol()}
              {formatLabel(data?.[0]?.[firstKey]) || 0}
            </text>
          </svg>
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default BigNumberTotal;
