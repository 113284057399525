import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";
import { RectangleBgIcon } from "@platformx/shared/static-assets";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    profileBannerSection: {
      "&.profileBannerWrapper": {
        background: `url(${RectangleBgIcon}) left center no-repeat, linear-gradient(82.84deg, #572AC2 1.73%, #7747D5 27.13%, #4080F5 58.6%, #7ABEF7 107.74%)`,
        alignItems: "center",
        backgroundPosition: "top center",
        padding: "20px",
        textAlign: "center",
        [theme.breakpoints.up("em")]: {
          backgroundPosition: "top left",
          display: "flex",
          minHeight: "310px",
          height: "310px",
          padding: 0,
          textAlign: "left",
        },
        "& .leftImg": {
          height: "100%",
          position: "relative",
          width: "100%",
          display: "flex",
          alignItems: "end",
          // transform: `translate(50%, 10px)`,
          [theme.breakpoints.up("em")]: {
            width: "340px",
          },
          "& img": {
            width: "100%",
            height: "291px",
            // position: "absolute",
            // bottom: 0,
            [theme.breakpoints.up("em")]: {
              maxWidth: "270px",
              height: "auto",
            },
          },
        },
        "& .rightPanel": {
          flexGrow: 1,
          height: "100%",
          [theme.breakpoints.up("em")]: {
            padding: "0 24px",
            paddingLeft: 0,
          },
          "& .keyHighlight": {
            width: "100%",
            textAlign: "center",
            [theme.breakpoints.up("em")]: {
              textAlign: "left",
            },
            "& .columnItem": {
              borderRight: `1px solid ${theme.palette.textColor}`,
            },
          },
        },
      },
    },
  };
});
