import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    profileCardWrapper: {
      "&.profileCardWrapperSection": {
        background: theme.palette.prelemType3.BACKGROUND,
        border: `solid 1px ${theme.palette.prelemType3.LINE}`,
        [theme.breakpoints.up("lg")]: {
          minHeight: "520px",
        },
        "& .profileCardHighlight": {
          textAlign: "center",
          // marginBottom: "24px",
          position: "relative",
          minHeight: "175px",
          "& .avtarWrapper": {
            position: "relative",
            minHeight: "98px",
            "& .profileAvtar": {
              width: "98px",
              height: "98px",
              margin: "auto",
              fontSize: "40px",
              marginTop: "16px",
              background: "#BDBDBD",
            },
            "& .tireBadge": {
              position: "absolute",
              bottom: "-17px",
              transform: `translate(-50%, -4px)`,
              width: "32px",
            },
          },
          "& .profileName": {
            marginTop: "20px",
            marginBottom: "0px",
          },
          "& .profileEdit": {
            position: "absolute",
            top: "-16px",
            margin: 0,
            minWidth: "36px",
            height: "36px",
            padding: 0,
            right: 0,
            zIndex: 1,
          },
        },
        "& .itemsFeature": {
          borderBottom: `solid 1px ${theme.palette.prelemType3.LINE}`,
        },
        "& .tertiaryParagraph": {
          marginBottom: "5px",
          wordBreak: "break-all",
        },
        "& .labelItems": {
          margin: 0,
          padding: 0,
          paddingTop: "8px",
          textTransform: "capitalize",
        },
      },
    },
  };
});
