import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Slider from "../../../../components/Slider/Slider";
import { getCurrency } from "../../helperEcommerce";

const OrderRecived = ({ orderDetail }) => {
  const { t } = useTranslation();
  const settings = {
    autoplay: true,
    showThumb: false,
    animationType: "slideIn",
    arrowPosition: "inside",
    arrows: true,
    dots: false,
    speed: 10000,
    infinite: false,
  };
  const renderSlider = () => {
    return orderDetail?.line_item?.map((item, i) => (
      <Box sx={{ display: "flex" }} key={i}>
        <Grid item>
          <Box className='orderedProduct'>
            <img
              src={item?.attr_images?.[0]}
              alt='product'
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </Box>
        </Grid>
        <Grid item xs>
          <Box sx={{ marginLeft: 2 }}>
            <Typography variant='p3medium' className='noMargin'>
              {item?.ecomx_name}
            </Typography>
            <Typography variant='p4regular' className='noMargin labelColor'>
              {item?.ecomx_attributes_brand}
              {/* Size: 37 | Color: Beige */}
            </Typography>
            <Typography variant='p2semibold'>
              {getCurrency(item?.ecomx_currency_code)}
              {item?.ecomx_sale_price}
            </Typography>
          </Box>
        </Grid>
      </Box>
    ));
  };
  return (
    <Box className='orderRecivedColumn'>
      <Typography variant='h2bold' color='tertiaryTitle' className='noMargin'>
        {t("order_received")}
      </Typography>
      <Typography variant='h5medium' sx={{ marginTop: "4px" }}>
        {t("thank_you")}
      </Typography>
      {orderDetail && orderDetail?.line_item && orderDetail?.line_item?.length > 1 ? (
        <Slider {...settings}>{renderSlider()}</Slider>
      ) : (
        renderSlider()
      )}
    </Box>
  );
};

export default OrderRecived;
