import { Box, Grow, TableContainer, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DropDown from "../../../DropDown/Dropdown";
import { getInitials, getLeaderBoard, getPointsBreakout, monthNames } from "../../helperProfile";
import { useCustomStyle } from "./LeaderBoard.style";
import SkeletonTable from "./SkeletonTable";

interface Leader {
  email: string;
  userName: string;
  rank: number;
  totalPoints: number;
  isCurrentUser: boolean;
}

const LeaderBoard = ({ secondaryArgs, profile }) => {
  const classes = useCustomStyle();
  const { t } = useTranslation();
  const [slectedEvent, setSelectedEvent] = useState<any>("");
  const [eventList, setEventList] = useState<any>([]);
  const [leaderBoard, setLeaderBoard] = useState<Leader[]>([]);
  const [loading, setLoading] = useState(true);
  const [isEventLoaded, setIsEventLoaded] = useState(false); // Add a state to track if events are loaded

  const handleChange = (event) => {
    setLoading(true);
    if (event?.target?.value) {
      setSelectedEvent(event?.target?.value);
    }
  };

  const getLeaderBoardApi = async () => {
    if (profile?.email && slectedEvent) {
      try {
        const res = await getLeaderBoard({
          secondaryArgs: secondaryArgs,
          userEmail: profile?.email,
          eventName: slectedEvent,
        });
        const { data: { data: { users_getLeaderBoardList = [] } = {} } = {} } = res;
        if (users_getLeaderBoardList?.length > 0) {
          setLeaderBoard(users_getLeaderBoardList);
        } else {
          setLeaderBoard([]);
        }
      } catch (error) {
        console.error("Error fetching leaderboard:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const getAllEvents = async () => {
    const res = await getPointsBreakout({
      secondaryArgs: secondaryArgs,
      userEmail: profile?.email,
    });

    const { data: { data: { users_fetchMemberTransactions = [] } = {} } = {} }: any = res;
    if (users_fetchMemberTransactions && users_fetchMemberTransactions.length > 0) {
      const transformedArray = [
        ...users_fetchMemberTransactions.map((item) => ({
          name: item.event,
          value: item.event,
        })),
      ];
      setEventList(transformedArray);
      setSelectedEvent(transformedArray?.[0]?.value);
      setIsEventLoaded(true);
    }
  };

  useEffect(() => {
    if (isEventLoaded) {
      getLeaderBoardApi();
    }
  }, [profile?.email, slectedEvent, isEventLoaded]);

  useEffect(() => {
    profile?.email && getAllEvents();
  }, [profile?.email]);

  const userIndex = leaderBoard?.findIndex((leader) => leader?.email === profile?.email);
  const getHighlighted = (index) => {
    return userIndex > 3 && index === 3;
  };

  return (
    <Box className={`${classes.leaderBoard} leaderBoardSection gap`}>
      <Box display={"flex"}>
        <Typography variant='h4medium' className='noTopMargin'>
          {`${monthNames[new Date().getMonth()]} ${t("event_leaderboard")}`}
        </Typography>
        {eventList && eventList?.[0]?.name && (
          <DropDown
            sx={{ maxWidth: "auto" }}
            label={eventList?.[0]?.name}
            name='eventName'
            arrayData={eventList}
            isDisabled={eventList?.length === 0}
            parentHandler={handleChange}
            stateValue={slectedEvent}
            cssClass='input-control-selectbox'
          />
        )}
      </Box>
      {loading ? (
        <SkeletonTable />
      ) : (
        <TableContainer>
          <Table aria-label='custom table'>
            <TableBody className='leaderBoardTable'>
              {leaderBoard?.map((leader, index) => (
                <Grow in={true} timeout={1000 * index} key={index}>
                  <TableRow
                    key={leader?.email}
                    className={getHighlighted(index) ? "lineSeprator" : ""}>
                    <TableCell width={"50px"} sx={{ padding: 1 }}>
                      <Avatar
                        className='avtarName'
                        alt={leader?.userName}
                        sx={{ width: 40, height: 40 }}>
                        {getInitials(leader?.userName, "")}
                      </Avatar>
                    </TableCell>
                    <TableCell sx={{ padding: 1 }}>
                      <Typography variant='h5medium'> {leader.userName}</Typography>
                    </TableCell>
                    <TableCell sx={{ padding: 1 }}>
                      <Typography variant='h5medium'> {leader.rank}</Typography>
                    </TableCell>
                    <TableCell sx={{ padding: 0 }} align='right'>
                      <Chip
                        label={
                          <Typography variant='p4semibold' className='points'>
                            {leader.totalPoints}
                          </Typography>
                        }
                        className='customChip'></Chip>
                    </TableCell>
                  </TableRow>
                </Grow>
              ))}
              {leaderBoard?.length === 0 && (
                <TableRow>
                  <TableCell sx={{ padding: 1 }}> No Data Found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default LeaderBoard;
