import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, Dialog, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DynamicContentGallery from "../ContentGallery/DynamicContentGallery";
import HiddenTextBox from "../HiddenTextBox/HiddenTextBox";
import "./Query.css";

type QueryProps = {
  field: any;
  state: any;
  errors: any;
  setState: any;
  register: any;
  clearErrors: any;
  isEdit: boolean;
};

const Query = (props: QueryProps) => {
  const { field, errors, isEdit, state, setState, register, clearErrors } = props;
  const { t } = useTranslation();
  const [options, setOptions] = useState("");
  const [openContentType, setContentType] = useState(false);
  const [pickedContent, setPickedContent] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const queryData =
    isEdit && (state?.[field?.name]?.tags?.length > 0 || state?.[field?.name]?.searchTerm)
      ? true
      : false;

  const open = Boolean(anchorEl);
  const id = open ? "query-popover" : undefined;

  const filterQueryAdd = (filteredNames: any = [], searchTerm1 = "") => {
    if (filteredNames || ([].length > 0 && ("" + searchTerm1).length > 0)) {
      return `${t("categories")} = ${filteredNames.join(", ")} ${t(
        "and_search_terms",
      )} "${searchTerm1}"`;
    } else if (filteredNames || [].length > 0) {
      return `${t("categories")} = ${filteredNames.join(", ")}`;
    } else if (("" + searchTerm1).length > 0) {
      return `${t("search_term")} = "${searchTerm1}"`;
    } else {
      return `${t("all")}`;
    }
  };

  /**
   * open contentType popUp
   */
  const contentTypeOpen = () => {
    setContentType(true);
  };

  /**
   * close contentType popUp
   */
  const onToggleContentGallery = () => {
    setContentType(false);
  };

  const commonProduct = (arr, searchData) => {
    const filteredNames = arr?.map((item) => `"${item}"`);
    const finalData = `${filterQueryAdd(filteredNames, searchData)} ${t("orderByCreateQuery")}`;
    setOptions(finalData);
  };

  const handleSelectedContent = (e) => {
    setState({ ...state, [field?.name]: e?.queryParam });
    setPickedContent(true);
    onToggleContentGallery();
    clearErrors(field?.name);
    commonProduct(e?.queryParam?.tags, e?.queryParam?.searchTerm);
  };

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDelete = () => {
    const newObj = { ...state, [field?.name]: {} };
    setAnchorEl(null);
    setState(newObj); //clear query
    setPickedContent(false); //enable add new button
  };

  const onEdit = () => {
    setContentType(true);
    handleClose();
  };

  useEffect(() => {
    if (queryData) {
      setPickedContent(true);
      // constract the content
      commonProduct(state?.[field?.name]?.tags, state?.[field?.name]?.searchTerm);
    }
  }, [isEdit, queryData]);

  return (
    <Box>
      {pickedContent ? (
        <Box className='queryBox'>
          <Box component={"span"} className='queryBox_content'>
            {options}
          </Box>
          <IconButton
            onClick={handleClickListItem}
            className='queryBoxOption'
            data-testid='selectoption'>
            <MoreVertIcon />
          </IconButton>
        </Box>
      ) : (
        <Button
          type='button'
          variant='primaryButton'
          className='addnewpage'
          onClick={contentTypeOpen}>
          {t("create_new")}
        </Button>
      )}

      {/*  */}
      <HiddenTextBox
        error={errors[field?.name]}
        value={state?.[field?.name]?.filter}
        register={register}
        name={field?.name}
        required={
          field?.validations?.length > 0 &&
          field?.validations?.[0]?.type === "required" &&
          Object.keys(state?.[field?.name] || {}).length === 0
            ? field?.validations?.[0]?.message
            : ""
        }
      />

      {/* contentType */}
      {openContentType ? (
        <Dialog fullScreen open={true}>
          <DynamicContentGallery
            prelemId={""}
            multi_select={false}
            pageFrom={"query"}
            handleSelectedContent={handleSelectedContent}
            selectedFilters={queryData ? state?.query : {}}
            onToggleContentGallery={onToggleContentGallery}
          />
        </Dialog>
      ) : null}

      {/* edit option  */}
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: -5,
        }}>
        <MenuItem sx={{ p: 1 }}>
          <Typography onClick={onEdit} sx={{ padding: "3px 10px 3px 10px" }}>
            {t("edit")}
          </Typography>
        </MenuItem>
        <MenuItem sx={{ p: 1 }}>
          <Typography onClick={onDelete} sx={{ padding: "3px 10px 3px 10px" }}>
            {t("delete")}
          </Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};
export default Query;
