import { Box, Container } from "@mui/material";
import {
  AccountSavedCard,
  AddressIcon,
  HelpIcon,
  LoyaltyPintIcon,
  OrderIcon,
  ProfileIcon,
  WishlistIcon,
} from "@platformx/shared/static-assets";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loyalty from "./Loyalty/Loyalty";
import Profile from "./Profile/Profile";
import ProfileDetails from "./ProfileDetails/ProfileDetails";
import ProfileMenu from "./ProfileMenu/ProfileMenu";
import { useCustomStyle } from "./UserProfile.style";
import { getUserDetailsService } from "./helperProfile";

// Define the details for each tab, including the component, props, and icons

const UserProfile = ({ secondaryArgs }) => {
  const classes = useCustomStyle();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(parseInt(secondaryArgs?.tab) || 0);
  const [shouldProfileUpdate, setShouldProfileUpdate] = useState(false);
  const [profile, setProfile] = useState({
    first_name: "",
    last_name: "",
    gender: "",
    image: "",
    phone: "",
    dob: "",
    email: "",
    loyalty_card_number: "",
    member_id: "",
  });
  const tabDetails = [
    {
      label: t("profile"),
      isDisabled: false,
      component: Profile,
      props: {},
      icon: ProfileIcon,
    },
    {
      label: t("loyalty_points"),
      isDisabled: false,
      component: Loyalty,
      icon: LoyaltyPintIcon,
    },
    {
      label: t("order"),
      isDisabled: true,
      component: Profile,
      icon: OrderIcon,
    },
    {
      label: t("wishlist"),
      isDisabled: true,
      component: Profile,
      icon: WishlistIcon,
    },
    {
      label: t("saved_cards"),
      isDisabled: true,
      component: Profile,
      icon: AccountSavedCard,
    },
    {
      label: t("saved_addresses"),
      isDisabled: true,
      component: Profile,
      icon: AddressIcon,
    },
    {
      label: t("help_center"),
      isDisabled: true,
      component: Profile,
      icon: HelpIcon,
    },
  ];

  const handleTabChange = (_event, newValue) => {
    setSelectedTab(newValue);
    if (window) {
      const newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        `?tab=${newValue}`;
      window.history.pushState({ path: newurl }, "", newurl);
    }
  };
  const getUserDetailsServiceApi = async () => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      const res = await getUserDetailsService({
        userId: userId,
        secondaryArgs: secondaryArgs,
      });
      const { status = 0, data: { data: { viewProfile = {} } = {} } = {} } = res;
      if (status === 200 && Object.keys(viewProfile || {}).length > 0) {
        setProfile(viewProfile);
      }
    }
  };
  useEffect(() => {
    setShouldProfileUpdate(false);
    getUserDetailsServiceApi();
  }, [shouldProfileUpdate]);

  return (
    <Box className={`${classes.userProfilePage} userProfileSection`}>
      <Container className='grid_container prelem-py grid_container_nopadding'>
        <Box className='mainContainerSection'>
          {/* Sidebar Profile Menus */}
          <ProfileMenu
            selectedTab={selectedTab}
            handleTabChange={handleTabChange}
            tabDetails={tabDetails}
          />
          {/* Profile Details */}
          <ProfileDetails
            selectedTab={selectedTab}
            tabDetails={tabDetails}
            secondaryArgs={secondaryArgs}
            profile={profile}
            setShouldProfileUpdate={setShouldProfileUpdate}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default UserProfile;
