import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Grid, Typography } from "@mui/material";
import { StartRewardIcon } from "@platformx/shared/static-assets";
import { useTranslation } from "react-i18next";

const OrderRewards = ({ secondaryArgs }) => {
  const { t } = useTranslation();

  const onViewClick = () => {
    window.location.href = `${secondaryArgs?.prelemBaseEndpoint?.PublishEndPoint}${secondaryArgs?.prelemBaseEndpoint?.language}/user/profile?tab=1`;
  };

  return (
    <Box className='orderRewardColumn'>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {/* Left Side - Your Rewards */}
        <Typography variant='h4medium' color='tertiaryTitle' className='noTopMargin'>
          {t("your_rewards")}
        </Typography>

        {/* Right Side - Next with Arrow */}
        <Box
          display='flex'
          alignItems='center'
          className='viewAll'
          color='tertiaryAnchorLink'
          onClick={onViewClick}>
          <Typography variant='p4medium' component='span' color='tertiaryAnchorLink'>
            {t("view_all")}
          </Typography>
          <KeyboardArrowRightIcon />
        </Box>
      </Box>
      {/* Left side - Loyalty */}
      <Box sx={{ display: "flex" }}>
        <Grid item>
          <Box className='starIcon'>
            <img
              src={StartRewardIcon}
              alt='Rewards'
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </Box>
        </Grid>

        {/* Right side - Text */}
        <Grid item xs>
          <Box ml={2}>
            <Typography variant='p4medium' className='noMargin'>
              36 {t("coins_earn_with_this_purchase")}
            </Typography>
            <Typography variant='p4regular' className='noMargin labelColor'>
              {t("it_will_be_uploaded_after_the_delivery")}
            </Typography>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default OrderRewards;
