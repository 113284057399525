import { Box, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCustomStyle } from "./ProfileMenu.style";

const ProfileMenu = ({ selectedTab, handleTabChange, tabDetails }) => {
  const classes = useCustomStyle();
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:1024px)");
  const getMenu = () => {
    return (
      <Tabs
        orientation={isMobile ? "horizontal" : "vertical"}
        variant='scrollable'
        value={selectedTab}
        onChange={handleTabChange}
        className='profile-menu'
        sx={{
          width: isMobile ? "100%" : "250px",
        }}>
        {tabDetails.map((tab, index) => (
          <Tab
            key={index}
            label={tab.label}
            disabled={tab.isDisabled}
            disableRipple
            icon={
              <img src={tab.icon} alt={`${tab.label} icon`} style={{ width: 24, height: 24 }} />
            }
            iconPosition='start'
            className='profile-menu-item'
          />
        ))}
      </Tabs>
    );
  };

  return (
    <Box className={`${classes.userProfilePageMenu} profileMenuSection`}>
      {isMobile ? (
        <Box className='profileDrawerMenu smallScreen'>{getMenu()}</Box>
      ) : (
        <Box className='profileDrawerMenu largeScreen'>
          <Typography variant='h5bold' className='heading'>
            {t("account_details")}
          </Typography>
          <Box>{getMenu()}</Box>
        </Box>
      )}
    </Box>
  );
};

export default ProfileMenu;
