import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import Achievement from "../Component/Achivement/Achivement";
import Challanges from "../Component/Challanges/Challanges";
import LoyaltyProgressBar from "../Component/LoyaltyProgressBar/LoyaltyProgressBar";
import MyStoryComponent from "../Component/MyStoryComponent/MyStoryComponent";
import ProfileCard from "../Component/ProfileCard/ProfileCard";
import VideoCard from "../Component/VideoCard/VideoCard";
import { getCombinedTireListOLProfile } from "../helperProfile";
import { useCustomStyle } from "./Profile.style";

const Profile = ({ secondaryArgs, profile, setShouldProfileUpdate }) => {
  const classes = useCustomStyle();
  const { member_id } = profile;
  const [userOlData, setUserOlData] = useState<any>(null);
  useEffect(() => {
    member_id && getUserOLProfileApi();
  }, [member_id]);

  const getUserOLProfileApi = async () => {
    try {
      const res = await getCombinedTireListOLProfile({
        secondaryArgs: secondaryArgs,
        memberId: member_id, //"cc52583f-08af-4a51-8191-090dd79f3a9f", //
      });
      setUserOlData(res);
    } catch (error) {
      console.error("Error fetching loyaltyProgressbar:", error);
    }
  };

  return (
    <Box className={`${classes.profileTabItem} profileTabSection`}>
      <Grid container>
        <Grid item xs={12} lg={4} className='leftSection'>
          <ProfileCard
            secondaryArgs={secondaryArgs}
            profile={profile}
            setShouldProfileUpdate={setShouldProfileUpdate}
            userOlData={userOlData}
          />
        </Grid>
        <Grid item xs={12} lg={8} className='rightSection'>
          <Grid container>
            {/* First full row inside Grid-8: Grid-12 */}
            <Grid item xs={12}>
              <Box className='Loyaltywrapper' mb={2}>
                <LoyaltyProgressBar secondaryArgs={secondaryArgs} memberId={member_id} />
              </Box>
            </Grid>

            {/* Second row inside Grid-8: Grid-7 and Grid-5 */}
            <Grid item xs={12} lg={7}>
              <Box className='ChallangesSection leftSection'>
                <Challanges secondaryArgs={secondaryArgs} />
              </Box>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Box className='achivementSection rightSection'>
                <Achievement />
              </Box>
              <Box className='achivementSection rightSection'>
                <VideoCard secondaryArgs={secondaryArgs} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* Third row */}
        {secondaryArgs?.globalSettings?.disable_my_stories ? (
          <Grid item xs={12}>
            <Box className='myStorySection' mt={2}>
              <MyStoryComponent secondaryArgs={secondaryArgs} />
            </Box>
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default Profile;
