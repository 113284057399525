import { ShowToastError } from "@platformx/utilities";
import { t } from "i18next";
import { onError } from "../../../utils/Helper";

export const fieldValidation = (
  pollState,
  isWorkflow,
  tagArr,
  answers,
  addImage,
  isError,
  setIsError,
) => {
  const {
    title,
    description,
    scoreBy,
    imagevideoURL,
    colorCode,
    queBackgroundColor,
    queBackgroundImg,
    is_schedule_publish,
    schedule_publish_datetime,
    is_schedule_unpublish,
    schedule_unpublish_datetime,
    titleSocialShare,
    descriptionSocialShare,
  } = pollState;
  if (isWorkflow) {
    const emptyAnswers = answers?.filter((ans) => ans.option === "");
    const emptyImageOptions = answers?.filter((ans) => ans.image === "");
    if (title === "") {
      onError("Title");
      setIsError({ ...isError, title: true });
      ShowToastError(`${t("title")} ${t("is_required")}`);
    } else if (description === "") {
      onError("Description");
      setIsError({ ...isError, description: true });
      ShowToastError(`${t("description")} ${t("is_required")}`);
    } else if (colorCode === "" && imagevideoURL === "") {
      onError("imageVideo");
      setIsError({ ...isError, imageVideo: true });
      ShowToastError(`${t("banner_image")} ${t("is_required")}`);
    } else if (pollState?.poll_title === "") {
      onError("pollTitle");
      setIsError({ ...isError, poll_title: true });
      ShowToastError(`${t("poll")} ${t("question")} ${t("title")} ${t("is_required")}`);
    } else if (pollState?.poll_description === "") {
      onError("pollDesc");
      setIsError({ ...isError, poll_description: true });
      ShowToastError(`${t("poll")} ${t("question")} ${t("description")} ${t("is_required")}`);
    } else if (queBackgroundColor === "" && queBackgroundImg === "") {
      onError("queBackgroundColor");
      setIsError({ ...isError, queBackgroundColor: true });
      ShowToastError(`${t("poll")} ${t("question")} ${t("banner_image")} ${t("is_required")}`);
    } else if (emptyAnswers.length > 0) {
      onError("answerContent");
      setIsError({ ...isError, emptyAnswers: true });
      ShowToastError(`${t("answers")} ${t("is_required")}`);
    } else if (addImage && emptyImageOptions.length > 0) {
      onError("answerContent");
      setIsError({ ...isError, emptyImageOptions: true });
      ShowToastError(t("empty_images"));
    } else if (scoreBy === "") {
      ShowToastError(`${t("score")} ${t("is_required")}`);
    } else if (is_schedule_publish && schedule_publish_datetime === "") {
      ShowToastError(`${t("scheduled_publish")} ${t("is_required")}`);
    } else if (is_schedule_unpublish && schedule_unpublish_datetime === "") {
      ShowToastError(`${t("scheduled_unpublish")} ${t("is_required")}`);
    } else if (tagArr?.length === 0) {
      ShowToastError(t("tag_error"));
    } else if (titleSocialShare === "") {
      ShowToastError(`${t("social_share")} ${t("title")} ${t("is_required")}`);
    } else if (descriptionSocialShare === "") {
      ShowToastError(`${t("social_share")} ${t("description")} ${t("is_required")}`);
    } else {
      return true;
    }
  } else {
    if (title === "") {
      onError("Title");
      setIsError({ ...isError, title: true });
      ShowToastError(`${t("title")} ${t("is_required")}`);
    } else if (description === "") {
      onError("Description");
      setIsError({ ...isError, description: true });
      ShowToastError(`${t("description")} ${t("is_required")}`);
    } else if (
      is_schedule_publish &&
      (schedule_publish_datetime === "" || schedule_publish_datetime === null)
    ) {
      ShowToastError(`${t("scheduled_publish")} ${t("time")} ${t("is_required")}`);
    } else if (
      is_schedule_unpublish &&
      (schedule_unpublish_datetime === "" || schedule_unpublish_datetime === null)
    ) {
      ShowToastError(`${t("scheduled_unpublish")} ${t("time")} ${t("is_required")}`);
    } else {
      return true;
    }
  }
};

export const socialShareTitleSubTitles = () => {
  return {
    sectionNumber: "07",
    sectionTitle: t("social_share"),
    sectionSubTitle: t("subhead"),
    imageTitle: t("choose_image"),
    imageSubTitle: t("page_choose_image"),
    mainTitle: t("poll_ss_title"),
    mainSubTitle: t("quiz_ss_subtitle"),
    titlePlaceHolder: t("quiz_title_placeholder"),
    descriptionTitle: t("poll_ss_description"),
    descriptionSubTitle: t("quiz_ss_subdescription"),
    descriptionPlaceHolder: t("quiz_description_placeholder"),
    tagsTitle: t("quiz_tags_title"),
    tagsSubTitle: t("quiz_tags_subtitle"),
    titleMaxCharLength: 120,
    descriptionMaxCharLength: 400,
    titleFieldName: "titleSocialShare",
    descriptionFieldName: "descriptionSocialShare",
    imageFieldName: "socialShareImgURL",
    tagsFieldName: "tagsSocialShare",
  };
};

export const analyticsTitleSubTitles = () => {
  return {
    sectionNumber: "08",
    sectionTitle: t("analytics"),
    sectionSubTitle: t("subhead"),
    analyticsTitle: `${t("poll")} ${t("analytics")}`,
    analyticsSubTitle: t("event_analytics_subhead"),
    impressionTitle: t("content_insight"),
    impressionSubTitle: "",
    analyticsFieldName: "analytics_enable",
    impressionFieldName: "impression",
  };
};

export const seoTitleSubTitles = () => {
  return {
    sectionNumber: "09",
    sectionTitle: t("SEO"),
    sectionSubTitle: t("subhead"),
    seoTitle: t("page_prelem_find"),
    seoSubTitle: t("seo_description"),
    shareTitle: t("page_prelem_share"),
    shareSubTitle: "",
    structureDataTitle: t("page_structure_data"),
    structureDataSubTitle: t("click_to_sd"),
    seoFieldName: "seo_enable",
    shareFieldName: "seo_shared",
    structureDataFieldName: "structure_data",
  };
};
