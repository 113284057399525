import { Box, Typography } from "@mui/material";

const ProfileDetails = ({
  selectedTab,
  tabDetails,
  secondaryArgs,
  profile,
  setShouldProfileUpdate,
}) => {
  // Render the selected component with its props
  const renderContent = () => {
    const { component: Component } = tabDetails[selectedTab];
    return (
      <Component
        secondaryArgs={secondaryArgs}
        profile={profile}
        setShouldProfileUpdate={setShouldProfileUpdate}
      />
    );
  };

  return (
    <Box sx={{ flexGrow: 1 }} className='profileDetail'>
      <Typography variant='h4medium' className='heading'>
        {tabDetails?.[selectedTab]?.label}
      </Typography>
      {renderContent()}
    </Box>
  );
};

export default ProfileDetails;
