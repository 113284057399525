import { TextareaAutosize } from "@mui/base";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { convertToLowerCase } from "../../utils/helperFns";
import { useCustomStyle } from "./AutoTextArea.style";

interface AutoTextAreaProps {
  name?: any;
  placeHolder?: any;
  handleChange?: any;
  maxCharLength?: any;
  state?: any;
  handleOnBlur?: any;
  isDisabled?: any;
  minRows?: number;
  maxRows?: number;
  error?: boolean;
  helperText?: string;
}
const AutoTextArea = ({
  name,
  placeHolder,
  handleChange,
  maxCharLength,
  state,
  handleOnBlur,
  isDisabled,
  minRows = 6,
  error,
  helperText,
}: AutoTextAreaProps) => {
  const classes = useCustomStyle();
  const { t } = useTranslation();
  const inlineCss = `
  @media screen and (max-height: 600px) and (orientation: landscape) {
    textarea{
      height:auto !important;
      overflow-y:hidden !important;
  }
  }
  @media only screen and (max-width: 767px){
    textarea{
      height:220px !important;
      overflow-y:auto !important;
  } 
}`;

  const [restOfChar, setRestOfChar] = useState({
    lengthOfState: 0,
    restOfLength: 0,
    reachLimit: false,
  });
  const { restOfLength = 0, reachLimit = false } = restOfChar;

  const handleLength = (valueData = "") => {
    if (maxCharLength) {
      const lengthOfChar = convertToLowerCase(valueData).length;
      const rest = valueData ? maxCharLength - lengthOfChar : 0;

      setRestOfChar({
        ...restOfChar,
        restOfLength: rest,
        lengthOfState: lengthOfChar,
        reachLimit: maxCharLength === lengthOfChar,
      });
    }
  };

  const onChange = (event: any = {}) => {
    if (handleChange) {
      handleChange(event);
    }
    const { target: { value = "" } = {} } = event;
    handleLength(value);
  };

  useEffect(() => {
    const htmlElement: any = document.querySelector(`#${name}`);
    htmlElement.value = state;
    handleLength(state);
  }, [state]);

  return (
    <Box mr={2} sx={{ marginRight: 0 }}>
      {/* <style>{inlineCss}</style> */}
      <TextareaAutosize
        className={`${classes.autoTextAreaWrapper} autoTextArea`}
        disabled={isDisabled}
        aria-label='minimum height'
        minRows={minRows}
        placeholder={placeHolder}
        name={name}
        id={name}
        // value={state || ""}
        onChange={(e) => onChange(e)}
        onBlur={(e) => handleOnBlur && handleOnBlur(e)}
        maxLength={maxCharLength}
        style={{
          border: error ? "1px solid red" : "solid 1px #ced3d9",
          width: "100%",
          resize: "none", // Disables manual resizing
          overflow: "auto", // Ensures scrollbars appear if needed
        }}
      />
      {error && (
        <Typography
          variant='p4regular'
          sx={{
            color: "#B71C1C",
          }}>
          {helperText}
        </Typography>
      )}
      {maxCharLength ? (
        <Typography variant='p4regular' sx={{ marginTop: "10px" }}>
          {reachLimit ? (
            <>0 {`${t("characters")} ${t("left")}`}</>
          ) : restOfLength ? (
            `${restOfLength} ${t("characters")} ${t("left")} `
          ) : (
            `${maxCharLength} ${t("characters")} ${t("max")}`
          )}
        </Typography>
      ) : null}
    </Box>
  );
};
export default memo(AutoTextArea);
