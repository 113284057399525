import { Box, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCustomStyle } from "./LinearProgressBar.style";

const LinearProgressBar = ({ dailyProgress }) => {
  const classes = useCustomStyle();
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const dayProgress = dailyProgress || 0;
    const MAXVAL = 500;
    setProgress((dayProgress / MAXVAL) * 100);
  }, [dailyProgress]);

  return (
    <Box className={`${classes.linearProgressBar} linearProgressBarSection gap`}>
      <Box display={"flex"} justifyContent={"space-between"}>
        <Typography variant='h4medium' className='noTopMargin'>
          {t("daily_progress")}
        </Typography>
        <Box>
          <Typography
            component='span'
            variant='h4bold'
            className='noMargin points'
            sx={{ whiteSpace: "nowrap" }}>
            {`${dailyProgress || 0} ${t("pts")}`}
          </Typography>
        </Box>
      </Box>
      {/* Progress Bar */}
      <LinearProgress variant='determinate' value={progress} className='linearBar' />
    </Box>
  );
};

export default LinearProgressBar;
