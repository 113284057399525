import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { getCurrency, getFullAddress } from "../../helperEcommerce";

const OrderDetails = ({ orderDetail }) => {
  const { t } = useTranslation();
  const data = [
    {
      key: t("subtotal"),
      value: `${getCurrency(orderDetail?.currency_code)}${orderDetail?.total_net_amount}`,
    },
    { key: t("shipping_and_handling_charges"), value: "$0" },
    {
      key: t("total_tax"),
      value: `${getCurrency(orderDetail?.currency_code)}${orderDetail?.total_tax}`,
    },
    {
      key: t("other_total"),
      value: `${getCurrency(orderDetail?.currency_code)}${orderDetail?.total_net_amount}`,
    },
  ];
  const isLastItem = (index) => {
    if (index && data && index === data?.length) {
      return true;
    }
    return false;
  };
  const getFormattedAddress = (keyName) => {
    const address = keyName in orderDetail ? orderDetail[keyName] : orderDetail["shipping_address"];
    return (
      <>
        {/* Name */}
        <Typography variant='p3bold' className='noMargin'>
          {address?.title} {address?.last_name}
        </Typography>
        <Typography variant='p4medium' className='noMargin description'>
          {getFullAddress(address)}
        </Typography>
        <Typography variant='p4medium' className='noMargin'>
          {`LandMark: ${address?.additional_address_info}`}
        </Typography>
        <Typography variant='p4medium' className='noMargin'>
          {`Contact Number: ${address?.mobile}`}
        </Typography>
      </>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12} md={5} em={7} lg={7}>
        <Typography variant='h2regular' color='tertiaryTitle'>
          {t("order_details")}
        </Typography>
        <Typography variant='p4bold' color='tertiaryAnchorLink' className='noMargin'>
          {t("order_id")} : {orderDetail?.id}
        </Typography>
        <TableContainer sx={{ mt: 2, mb: 4 }}>
          <Table aria-label='Product Details' size='small' className='productOrderDetail'>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell component='th' scope='row' sx={{ paddingLeft: 0 }}>
                    <Typography
                      variant={isLastItem(index + 1) ? "h5bold" : "h5regular"}
                      className='noMargin'>
                      {row.key}
                    </Typography>
                  </TableCell>
                  <TableCell align='right' sx={{ paddingRight: 0 }}>
                    <Typography
                      variant={isLastItem(index + 1) ? "p3bold" : "p3regular"}
                      className='noMargin'>
                      {row.value}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12} md={7} em={5} lg={5}>
        <Box className='address'>
          <Box className='shippingAddress'>
            <Typography variant='labelregular' className='subheading'>
              {t("shipping_details")}
            </Typography>
            {getFormattedAddress("shipping_address")}
          </Box>
          <Box className='billingAddress'>
            <Typography variant='labelregular' className='subheading'>
              {t("billing_details")}
            </Typography>
            {getFormattedAddress("billing_address")}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default OrderDetails;
