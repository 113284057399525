import { Box, Grid, Typography } from "@mui/material";
import { CarttonProfile1, CarttonProfile2 } from "@platformx/shared/static-assets";
import LoyaltyProgressBar from "../LoyaltyProgressBar/LoyaltyProgressBar";
import { useCustomStyle } from "./ProfileBanner.style";
import ProfileBannerSkelton from "./ProfileBannerSkelton";

const ProfileBanner = ({ userOlData, secondaryArgs, profile }) => {
  const classes = useCustomStyle();
  const getFullName = () => {
    return `${userOlData?.userProfileInfo?.firstName} ${userOlData?.userProfileInfo?.lastName}`;
  };
  const getAvatar = () => {
    return profile?.gender?.toLowerCase() === "female" ? CarttonProfile2 : CarttonProfile1;
  };

  return (
    <Box className={`${classes.profileBannerSection} profileBannerWrapper`}>
      {/* Left Image Section */}
      <Box className='leftImg'>
        <Box component='img' src={getAvatar()} alt='Image' />
      </Box>
      {/* Right Text Section */}
      <Box className='rightPanel'>
        <Box sx={{ minHeight: "160px" }}>
          {userOlData ? (
            <>
              <Typography variant='h4regular' color='textColor' className='noBottomMargin'>
                Hi,
              </Typography>
              <Typography variant='h1medium' color='textColor' className='noMargin'>
                {getFullName()}
              </Typography>
              <Box mt={2} mb={2} width={"100%"} className='keyHighlight'>
                <Grid container>
                  {/* First Column */}
                  <Grid item xs={4} lg={3} className='columnItem'>
                    <Typography variant='p4regular' color='textColor' className='noMargin'>
                      Total Points
                    </Typography>
                    <Typography variant='h3bold' color='textColor' className='noMargin'>
                      {userOlData?.userPointsInfo?.totalEarnedPoints || 0}
                    </Typography>
                  </Grid>

                  {/* Second Column */}
                  <Grid item xs={4} lg={3} className='columnItem' pl={2}>
                    <Typography variant='p4regular' color='textColor' className='noMargin'>
                      Active Pts
                    </Typography>
                    <Typography variant='h3bold' color='textColor' className='noMargin'>
                      {userOlData?.userPointsInfo?.activePoints || 0}
                    </Typography>
                  </Grid>

                  {/* Third Column */}
                  <Grid item xs={4} lg={3} pl={2}>
                    <Typography variant='p4regular' color='textColor' className='noMargin'>
                      Spent Pts
                    </Typography>
                    <Typography variant='h3bold' color='textColor' className='noMargin'>
                      {userOlData?.userPointsInfo?.spentPoints || 0}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <ProfileBannerSkelton />
          )}
        </Box>
        <LoyaltyProgressBar secondaryArgs={secondaryArgs} memberId={profile?.member_id} />
      </Box>
    </Box>
  );
};

export default ProfileBanner;
