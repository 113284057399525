import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    loyaltyTabItem: {
      "&.loyaltyTabSection": {
        background: theme.palette.prelemType1.BACKGROUND,
        "& .grid_container.prelem-py ": {
          width: "100% !important",
        },
        "& .leftSection": {
          paddingRight: "8px",
          [theme.breakpoints.down("lg")]: {
            paddingRight: 0,
            marginBottom: "16px",
          },
        },
        "& .rightSection": {
          paddingLeft: "8px",
          [theme.breakpoints.down("lg")]: {
            paddingLeft: 0,
          },
        },
      },
    },
  };
});
