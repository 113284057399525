export const ContentTypes = [
  { id: "ALL", value: "ALL" },
  { id: "Images", value: "Image Gallery" },
  { id: "Videos", value: "Video Gallery" },
  { id: "Gallery", value: "Gallery" },
  { id: "Accolades", value: "Accolades" },
  { id: "ServiceCard", value: "Service Card" },
  { id: "Articles", value: "Articles" },
  { id: "VOD", value: "VOD" },
  { id: "Quiz", value: "Quiz" },
  { id: "Poll", value: "Poll" },
];

export const ContentGalleryTypes = [
  "ALL",
  "ServiceCard",
  "ImageGallery",
  "VideoGallery",
  "Gallery",
  "Accolades",
  "Article",
  "VOD",
  "Quiz",
  "Poll",
  "Event",
];

export const ContentGalleryTypes1 = [
  "ImageGallery",
  "VideoGallery",
  "Gallery",
  "Article",
  "VOD",
  "Quiz",
  "Poll",
  "Event",
];

export const LanguageList = [
  { id: "en", value: "English (UK)" },
  { id: "fr", value: "French" },
  { id: "de", value: "German" },
];

export const DefaultLocale = "en";
export const CATEGORY_CONTENT = "content";
export const CATEGORY_PAGE = "Page";
export const SORTED_ORDER = "ASC";
export const MENU_STATE_DRAFT = "DRAFT";
export const MENU_TAGGING = "Navigation";
export const USERNAME_EMAIL_EXIST = "Username already exist!";
export const SITE_PAGE = "SitePage";
export const SUBCOMMUNITIES = "SUBCOMMUNITIES";
export const ASSETS = "ASSETS";
export const AUTHOR = "author";

export const ImageCropOrder = {
  1440: "hero",
  1280: "landscape",
  1024: "card2",
  768: "square",
  600: "card1",
  320: "portrait",
};

export const SNOWPLOW = {
  NA: "NA",
  SNOWPLOW: "snowplow",
  TRACKID: "selfDescribingEvent",
  IMPRESSIONTYPE: "user register Impression",
  REGISTERFROM: "Rendering",
  COLLECTOR_URL: "collector.hcl-x.com",
  APP_ID: "x",
  APP_NAME: "plateform-x-authoring-app",
};

export const CONTENT_ICON = {
  ARTICLE: "machine_assets/1702913611660/public/png/article.png",
  POLL: "machine_assets/1702914792308/public/png/poll1.png",
  QUIZ: "machine_assets/1702914846437/public/png/quiz-(1).png",
  EVENT: "machine_assets/1702913324953/public/png/EventWhiteIcon.png",
  VOD: "machine_assets/1702914916013/public/png/vod.png",
};

export const IMAGE_CROP_API_END_PATH = "api/v1/assets/image/";
