import { Box, TextField, useTheme } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useState } from "react";
import { Controller } from "react-hook-form";
import ErrorMessageHandle from "../Error/ErrorMessageHandle";

interface DatePickerProps {
  disablePast?: boolean;
  state?: any;
  setState?: any;
  field?: any;
  register: any;
  clearErrors: any;
  error: boolean;
  control: any;
}

const DatePickerComponent = ({
  state,
  setState,
  field,
  register,
  clearErrors,
  error,
  disablePast,
  control,
}: DatePickerProps) => {
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  DatePickerComponent.defaultProps = {
    disablePast: false,
    state: {},
    setState: () => {},
    field: {
      name: "",
      validations: [],
    },
  };
  const theme = useTheme();
  const [selectedDate, setSelectedDate] = useState(null);
  const handleDateChange = (date) => {
    if (error) {
      clearErrors(field.name);
    }
    setSelectedDate(date);
    setState({
      ...state,
      [field.name]: new Date(date).toISOString(),
    });
  };

  const validateDate = () => {
    const currentValue = state[field.name];
    const required = field?.validations?.find((x) => x.type === "required");
    if (required && !currentValue) {
      return required?.message;
    }
    if (disablePast && new Date(currentValue) < new Date()) {
      return "Please select a future date";
    }
    const validations = field.validations?.filter((x) => x.type === "before" || x.type === "after");
    for (const validation of validations) {
      const dependentName = validation?.dependent_name;
      const dependentValue = state[dependentName];
      const errorMessage = validation?.message;
      if (dependentValue) {
        const condition = {
          before: (otherValue) => new Date(currentValue) <= new Date(otherValue),
          after: (otherValue) => new Date(currentValue) >= new Date(otherValue),
        };

        const isValid = condition[validation.type](dependentValue);

        if (!isValid) {
          return errorMessage;
        }
      }
    }
    return true;
  };

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Controller
          name={field.name}
          control={control}
          rules={{ validate: validateDate }}
          render={({ field: controllerField }) => (
            <DatePicker
              {...controllerField}
              disablePast={disablePast}
              renderInput={(params) => (
                <TextField
                  variant='filled'
                  name={field.name}
                  // helperText={!selectedDate && helperText}
                  error={error}
                  {...register(field.name, {
                    required: !selectedDate && field?.validations[0]?.message,
                  })}
                  {...params}
                  onKeyDown={handleDateChangeRaw}
                  autoComplete='off'
                  sx={{
                    width: "100%",
                    border: error ? `solid 1px ${theme.palette.customColor.ERROR}` : ``,
                    borderRadius: "5px",
                    backgroundColor: error
                      ? `${theme.palette.customColor.BACKGROUND_ERROR}`
                      : "transparent",
                  }}
                />
              )}
              inputFormat='YYYY-MM-DD'
              value={state[field.name] === "" ? selectedDate : state[field.name]}
              onChange={handleDateChange}
            />
          )}
        />
      </LocalizationProvider>
      <ErrorMessageHandle error={error} />
    </Box>
  );
};
DatePickerComponent.defaultProps = {
  disablePast: false,
  state: {},
  setState: () => {},
  field: {
    name: "",
    validations: [],
  },
};

export default DatePickerComponent;
