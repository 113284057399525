import { Box, Tooltip as MuiTooltip, Typography } from "@mui/material";
import {
  Area,
  Bar,
  CartesianGrid,
  ComposedChart,
  LabelList,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { graph } from "../Constants";
import { addEllipsis, formatLabel, formatTooltipValue, isTimestampKey } from "../utils/helper";

const MixedChart = ({ itemData }: any) => {
  const {
    chartData: data,
    chartData1: data1,
    column_names: colnames,
    column_names1: colnames1,
    title,
    graph_type,
    meta_data,
  } = itemData;
  const [firstKey] = Object.keys(data?.[0]);
  const isTimestamp = isTimestampKey(firstKey, graph.timeStampFormates);
  const mergedData = data?.map((barData) => {
    const otherchartData = data1?.find((ld) => {
      return ld[colnames1[0]] === barData[colnames1[0]];
    });
    return {
      ...barData,
      ...otherchartData,
    };
  });
  const config = graph.bar;
  const configLine = graph.line;
  const configArea = graph.area;
  const gradients = mergedData?.slice(1)?.map((_, index) => (
    <linearGradient key={`gradient${_}`} id={`MyGradient${index}`} x1='0' y1='0' x2='0' y2='1'>
      <stop
        offset='5%'
        stopColor={configArea.graphColor[index % configArea.graphColor.length]}
        stopOpacity={0.4}
      />
      <stop
        offset='95%'
        stopColor={configArea.graphColor[index % configArea.graphColor.length]}
        stopOpacity={0}
      />
    </linearGradient>
  ));
  const truncateLabel = (label, maxLength) => {
    if (label.length > maxLength) {
      return addEllipsis(label, config.textMaxLength);
    }
    return label;
  };
  return (
    <Box className={`${graph_type} stackChart pageGraph`}>
      <MuiTooltip title={title} placement='top-start'>
        <Typography variant='h5semibold' className='heading'>
          {title}
        </Typography>
      </MuiTooltip>
      <ResponsiveContainer width={config.width} height={config.height} className='noxyAxsis'>
        <ComposedChart data={mergedData} margin={{ top: 20, right: 10, left: -10, bottom: 50 }}>
          {config.showGrid && <CartesianGrid strokeDasharray='3 3' />}
          {isTimestamp ? (
            <XAxis
              dataKey={firstKey}
              interval={0}
              textAnchor='end'
              angle={config.textXAngle}
              // scale="time"
              tickFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()}
              tick={{ fontSize: config.fontSize, fill: config.textColor }}
            />
          ) : (
            <XAxis
              dataKey={colnames?.[0]}
              tick={{
                fontSize: config.fontSize,
                fill: config.textColor,
                // alignmentBaseline: "mathematical",
              }}
              tickMargin={30}
              angle={config.textXAngle}
              dx={-25}
              tickFormatter={(label) => truncateLabel(label, config.textMaxLength)}
            />
          )}
          <YAxis
            tick={{ fontSize: config.fontSize, fill: config.textColor }}
            tickFormatter={(label) => formatLabel(label)}
          />
          {isTimestamp ? (
            <Tooltip labelFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()} />
          ) : (
            <Tooltip formatter={formatTooltipValue} />
          )}
          <Bar dataKey={colnames?.[1]} barSize={20} fill={config.graphColor[0]} />
          <defs>{gradients}</defs>
          {colnames1?.slice(1)?.map((key, index) =>
            meta_data?.areaB ? (
              <Area
                key={key}
                type={configArea.type as any}
                dataKey={key}
                stackId={index}
                stroke={configArea.graphColor[index % configArea.graphColor.length]}
                strokeWidth={configArea.strokeWidth}
                fill={`url(#MyGradient${index})`}>
                {configArea.showValuesOnTop && (
                  <LabelList
                    dataKey={key}
                    name={key}
                    position='top'
                    fontSize={configArea.fontSize}
                    fill={configArea.graphColor[index]}
                  />
                )}
              </Area>
            ) : (
              <Line
                key={key}
                type='monotone'
                dataKey={key}
                strokeWidth={configLine.strokeWidth}
                stroke={configLine.graphColor[index % configLine.graphColor.length]}
                fill={configLine.graphColor[index % configLine.graphColor.length]}
              />
            ),
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default MixedChart;
