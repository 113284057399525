import { Box, Skeleton } from "@mui/material";

const ProfileBannerSkelton = () => {
  return (
    <Box sx={{ width: "100%", padding: "20px 0px 46px 0" }}>
      {/* Top Label */}
      <Skeleton variant='text' width='50px' height={20} sx={{ mb: 1 }} />

      {/* Heading */}
      <Skeleton variant='text' width='40%' height={20} sx={{ mb: 3 }} />

      {/* Three Column Layout */}
      <Box display='flex' justifyContent='space-between' alignItems='flex-start'>
        {/* Column 1 */}
        <Box sx={{ flex: 1, textAlign: "center", position: "relative" }}>
          <Skeleton variant='text' width='40%' height={15} sx={{ mb: 1 }} />
          <Skeleton variant='text' width='20%' height={15} />
        </Box>

        {/* Column 2 */}
        <Box sx={{ flex: 1, textAlign: "center", position: "relative" }}>
          <Skeleton variant='text' width='40%' height={15} sx={{ mb: 1 }} />
          <Skeleton variant='text' width='20%' height={15} />
        </Box>

        {/* Column 3 */}
        <Box sx={{ flex: 1, textAlign: "center" }}>
          <Skeleton variant='text' width='40%' height={15} sx={{ mb: 1 }} />
          <Skeleton variant='text' width='20%' height={15} />
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileBannerSkelton;
