import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCustomStyle } from "./Achivement.style";

const Achievement = () => {
  const classes = useCustomStyle();
  const { t } = useTranslation();
  return (
    <Box className={`${classes.achivementWrapper} achivementSection gap`}>
      {/* First paragraph and heading */}
      <Box className='achivement'>
        <Typography variant='p4regular' color='textColor' className='noMargin'>
          {t("achievements")}
        </Typography>
        <Typography variant='h2bold' color='textColor' className='noMargin'>
          08
        </Typography>
      </Box>

      {/* Second paragraph and heading */}
      <Box className='accomplishment'>
        <Typography variant='p4regular' color='textColor' className='noMargin'>
          {t("accomplishments")}
        </Typography>
        <Typography variant='h2bold' color='textColor' className='noMargin'>
          12
        </Typography>
      </Box>
    </Box>
  );
};

export default Achievement;
