import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const OrderActions = () => {
  const { t } = useTranslation();
  const trackOrder = () => {};

  return (
    <Box className='orderActionColumn'>
      <Typography variant='h4medium' color='tertiaryTitle' className='noTopMargin'>
        {t("more_actions")}
      </Typography>
      <Button
        variant='tertiaryButton1'
        className='sm'
        disabled={true}
        onClick={trackOrder}
        type='button'
        sx={{ width: "100%" }}>
        {t("track_order")}
      </Button>
      <Button
        variant='tertiaryButton2'
        className='sm'
        onClick={trackOrder}
        type='button'
        disabled={true}
        sx={{ width: "100%" }}>
        {t("download_invoice")}
      </Button>
    </Box>
  );
};

export default OrderActions;
