import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { MoreHorizIcon } from "@platformx/shared/static-assets";
import { IconAndLabel, TitleSubTitle } from "@platformx/utilities";

import React, { useState } from "react";

const MIN__ITEMS_TO_SHOW = 4;

interface PopulatedItem {
  title: string;
  name: string;
}

export const DraggableItems = ({
  state,
  setState,
  name,
  populatedItems = [],
}: {
  state: any;
  setState: any;
  name: string;
  populatedItems: PopulatedItem[];
}) => {
  const [showAll, setShowAll] = useState(false);
  const renderItems = showAll ? populatedItems : populatedItems?.slice(0, MIN__ITEMS_TO_SHOW);
  const [items, setItems] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, val) => {
    setAnchorEl(event.currentTarget);
    setItems(val);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDeleteOption = (id) => {
    const tempQue = state[name].filter((item) => item.content_name !== id);
    setState({ ...state, [name]: tempQue });
    setAnchorEl(null);
  };

  return (
    <>
      <Box>
        <Box>
          {renderItems?.map((item) => {
            return (
              <Box key={item?.name} sx={{ padding: "5px 5px 5px 0px" }}>
                <Box
                  className='category-container'
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    border: "solid 1px #ced3d9",
                    borderRadius: "5px",
                    padding: "13px 14px",
                    width: "100%",
                  }}>
                  <Box
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                    }}>
                    <TitleSubTitle
                      subTitle={item?.title}
                      titleVariant='p4regular'
                      subTitleVariant='h6regular'
                    />
                  </Box>
                  <Box>
                    <IconButton
                      onClick={(e) => handleClick(e, item?.name)}
                      data-testid='content-click-item'
                      sx={{ width: "32px", height: "32px" }}>
                      <img
                        src={MoreHorizIcon}
                        style={{ objectFit: "cover", transform: "rotate(90deg)" }}
                        alt='icon'
                      />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
        {/* </Drop> */}
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          sx={{
            ".Platform-x-Menu-paper": {
              boxShadow: "0 10px 25px 0 rgba(0, 0, 0, 0.12);",
              borderRadius: "5px",
              paddingLeft: "0px",
              paddingRight: "0px",
            },
          }}>
          <MenuItem onClick={() => onDeleteOption(items)} data-testid='content-delete-option'>
            <CloseOutlinedIcon sx={{ marginRight: "5px", color: "#6e7191" }} />
            Remove
          </MenuItem>
        </Menu>
      </Box>
      {/* </DragAndDrop> */}
      {state[name]?.length > MIN__ITEMS_TO_SHOW && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {showAll ? (
            <IconAndLabel
              icon={<VisibilityIcon />}
              label='View less'
              handelClick={setShowAll}
              funcProp={false}
            />
          ) : (
            <IconAndLabel
              icon={<VisibilityIcon />}
              label='View all'
              handelClick={setShowAll}
              funcProp
            />
          )}
        </Box>
      )}
    </>
  );
};
