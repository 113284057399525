import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    myStorySection: {
      "&.myStorySectionBg": {
        background: theme.palette.prelemType1.BACKGROUND,
        border: `solid 1px ${theme.palette.prelemType1.LINE}`,
        "& .dynamicPrelemWithCarousel2Wrapper": {
          background: theme.palette.prelemType1.BACKGROUND,
          "& .grid_container.prelem-py": {
            width: "100%",
            padding: "20px !important",
            "& #Title": {
              color: theme.palette.prelemType1.Title,
              fontSize: "20px",
              margin: 0,
            },
            "& .noResultFound": {
              "& p": {
                color: theme.palette.prelemType1.Title,
              },
            },
            "& .XCard5Box": {
              "& .XCard5innderBox": {
                "& .imgWrapper": {
                  [theme.breakpoints.between("em", "lg")]: {
                    height: "200px",
                  },
                  [theme.breakpoints.up("lg")]: {
                    height: "300px",
                  },
                },
              },
            },
          },
        },
      },
    },
  };
});
