import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import { getCombinedTireListOLProfile, getTierByPoints, getTierIcon } from "../../helperProfile";
import { useCustomStyle } from "./LoyaltyProgressBar.style";
import LoyaltySkelton from "./LoyaltySkelton";

// Styled component for each section
const ProgressBarSection = styled(Box)<{ width: number; filledWidthItem: number }>(
  ({ width, filledWidthItem }) => ({
    position: "relative",
    background: `rgba(239, 240, 246, 1)`, // Background for empty portion
    width: `${width}%`,
    height: 10,
    // overflow: "hidden",
    "&:last-of-type": {
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
      "&::before": {
        borderTopRightRadius: "8px",
        borderBottomRightRadius: "8px",
      },
    },
    "&:first-of-type": {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
      "&::before": {
        borderTopLeftRadius: "4px",
        borderBottomLeftRadius: "4px",
        background: "linear-gradient(270deg, #80C683 18.82%, #409344 138.55%)",
      },
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      width: `${filledWidthItem}%`, // Dynamic filling width
      height: "100%",
      background: `linear-gradient(270deg, #80C683 18.82%, #80C683 138.55%)`,
    },
    "&::after": {
      content: '""',
      position: "absolute",
      top: "-6px",
      right: 0,
      height: "25px",
      borderRight: `1px solid #EFF0F6`,
    },
  }),
);

const LoyaltyProgressBar = ({ secondaryArgs, memberId }) => {
  const classes = useCustomStyle();
  const [loading, setLoading] = useState(true);
  const [userOlData, setUserOlData] = useState<any>(null);
  let sectionWidths: number[] = [];

  useEffect(() => {
    memberId && getUserOLProfileApi();
  }, [memberId]);

  const getUserOLProfileApi = async () => {
    try {
      const res = await getCombinedTireListOLProfile({
        secondaryArgs: secondaryArgs,
        memberId: memberId, //"cc52583f-08af-4a51-8191-090dd79f3a9f", //
      });
      setUserOlData(res);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching loyaltyProgressbar:", error);
    } finally {
      setLoading(false);
    }
  };

  const getTiresMaxValue = () => {
    return userOlData?.getTierList?.reduce((acc, level) => acc + level.condition[0].value, 0);
  };
  const drawProgressBar = () => {
    const maxValue = getTiresMaxValue(); // This will give you the total max value
    const achievedValue = userOlData?.userOLProfile?.userPointsInfo?.totalEarnedPoints || 0;

    // Calculate total fill percentage based on achieved value
    const totalFillPercentage = (achievedValue / maxValue) * 100;

    // Calculate section widths based on the conditions of the tiers
    sectionWidths =
      userOlData?.getTierList?.slice(1).map((tier, index, array) => {
        const previousTierValue = index === 0 ? 0 : array?.[index - 1]?.condition?.[0]?.value;
        const currentTierValue = tier?.condition?.[0]?.value || 0;

        // Calculate width as a percentage of the max value
        return ((currentTierValue - previousTierValue) / maxValue) * 100;
      }) || [];

    // For the last tier, we want to calculate the remaining width
    const lastTierWidth = 100 - sectionWidths.reduce((acc, width) => acc + width, 0);
    sectionWidths.push(lastTierWidth); // Add remaining width for the last tier

    return userOlData?.getTierList?.map((_, index) => {
      const previousFilledPercentage =
        index === 0 ? 0 : sectionWidths.slice(0, index).reduce((acc, width) => acc + width, 0);
      let filledWidthItem = 0;
      // Calculate how much of this section can be filled
      if (totalFillPercentage >= previousFilledPercentage + sectionWidths[index]) {
        filledWidthItem = 100; // Fully fill this section
      } else {
        // Fill only the remaining percentage for this section
        filledWidthItem = totalFillPercentage - previousFilledPercentage;
      }

      return (
        <ProgressBarSection
          key={index}
          width={sectionWidths[index]} // Use calculated section width
          filledWidthItem={filledWidthItem}
        />
      );
    });
  };

  return (
    <Box className={`${classes.loyaltyProgressBar} loyaltyProgressBarSection`}>
      {loading ? (
        <LoyaltySkelton />
      ) : (
        <Box display='flex'>
          {userOlData ? (
            <>
              <Box
                component='img'
                src={getTierIcon(userOlData)}
                alt='Level'
                sx={{
                  height: "auto",
                  width: "80px",
                  padding: "10px",
                  marginRight: "20px",
                }}
              />
              <Box sx={{ flexGrow: 1, padding: "13px 20px 10px 0", height: "100%" }}>
                <Box display='flex' justifyContent='space-between'>
                  <Typography
                    variant='p3semibold'
                    align='left'
                    color='textColor'
                    mb={0.5}
                    className='noTopMargin'>
                    {getTierByPoints(userOlData)}
                  </Typography>
                  <Typography
                    variant='p3semibold'
                    align='left'
                    color='textColor'
                    mb={0.5}
                    className='noTopMargin'>
                    {`${userOlData?.userOLProfile?.userPointsInfo?.totalEarnedPoints || 0} PTS`}
                  </Typography>
                </Box>
                {/* The progress bar itself */}
                <Box display='flex' width='100%' borderRadius='8px'>
                  {drawProgressBar()}
                </Box>
                {/* Labels above the progress bar */}
                <Box display='flex' justifyContent='space-between' mt={1}>
                  {userOlData.getTierList?.map((level, index) => (
                    <Typography
                      key={index}
                      variant='p4regular'
                      align='center'
                      color='textColor'
                      className='noMargin'
                      sx={{ width: `${sectionWidths[index]}%` }}>
                      {level.tierName}
                    </Typography>
                  ))}
                </Box>
              </Box>
            </>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default LoyaltyProgressBar;
