import { BronzeIcon, GoldIcon, PlatinumIcon, SilverIcon } from "@platformx/shared/static-assets";
import { postRestApiCall } from "@platformx/utilities";

// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace Intl {
  type Key = "calendar" | "collation" | "currency" | "numberingSystem" | "timeZone" | "unit";
  // eslint-disable-next-line no-unused-vars
  function supportedValuesOf(input: Key): string[];
}

export const timeZoneData = () => {
  return Intl.supportedValuesOf("timeZone");
};

export const getUniqueTimeZone = () => {
  const aryIannaTimeZones = timeZoneData();
  const data: { label: string; time: string }[] = [];
  aryIannaTimeZones.forEach((timeZone) => {
    const strTime = new Date().toLocaleString([], {
      timeZone: `${timeZone}`,
      hour12: false,
    });
    const time = new Date(strTime).toTimeString().slice(0, -21);
    data.push({ label: `${timeZone} ${time}(IST)`, time: `${strTime}` });
  });
  return data;
};

/**
 * user details api call
 */
export const getUserDetailsService = (ele: any) => {
  const { secondaryArgs = {}, userId = "" } = ele;
  const { prelemBaseEndpoint: { language = "en", deliveryEndPoint = "", site_host = "" } = {} } =
    secondaryArgs;

  const data = JSON.stringify({
    query: `query{
    viewProfile( user_id: ${JSON.stringify(userId)}
    )
     {
        user_id
        dob
        gender
        username
        enabled
        first_name
        last_name
        email
        timezone
        image
        phone
        role
        default_site
        preferred_sites_languages
        accessible_sites
        preferred_sites_urls
        loyalty_card_number
        member_id
        }

    }`,
    variables: {},
  });
  return postRestApiCall(`${deliveryEndPoint}delivery-engine`, data, language, site_host);
};

/**
 * user details update api call
 */
export const updateUserDetailsService = (ele: any) => {
  const { secondaryArgs = {}, userId = "", userDetails = {} } = ele;
  const { prelemBaseEndpoint: { language = "en", deliveryEndPoint = "", site_host = "" } = {} } =
    secondaryArgs;

  const data = JSON.stringify({
    query: `mutation ($input: updateRequest){
      updateUserProfile(input: $input) {    
      message
      }
  }`,
    variables: {
      input: {
        first_name: userDetails.firstName,
        last_name: userDetails.lastName,
        timezone: userDetails.timezone,
        role_id: null,
        image: "",
        phone: userDetails.mobileNumber,
        enabled: true,
        id: userId,
        default_site: null,
        accessible_sites: null,
        preferred_sites_languages: userDetails.defaultLanguage || "en",
        preferred_sites_urls: null,
        dob: userDetails.dob,
        gender: userDetails.gender,
      },
    },
  });
  return postRestApiCall(`${deliveryEndPoint}delivery-engine`, data, language, site_host);
};

/**
 * user CampaignList api call
 */
export const getUserCampaignList = (ele: any) => {
  const { secondaryArgs = {} } = ele;
  const { prelemBaseEndpoint: { language = "en", deliveryEndPointGq = "", site_host = "" } = {} } =
    secondaryArgs;

  const data = JSON.stringify({
    query: `query{
    users_getCampaignList(
     pagination: {start: 1, rows: 10}
    sort: asc
    ){
        campaignName
        campaignId
        isActive
        startsAt
        endsAt
        imageUrl
        destinationUrl
        pointsEarns
    }
    }`,
    variables: {},
  });
  return postRestApiCall(`${deliveryEndPointGq}`, data, language, site_host);
};

export const getUserOLProfile = (ele: any) => {
  const { secondaryArgs = {}, memberId = "" } = ele;
  const { prelemBaseEndpoint: { language = "en", deliveryEndPointGq = "", site_host = "" } = {} } =
    secondaryArgs;

  const data = JSON.stringify({
    query: `query {
  users_userOLProfile(
   memberId:${JSON.stringify(memberId)}
  ) {
    userProfileInfo {
      firstName
      lastName
      loyaltyCardNumber
      referalCode
    }
    userPointsInfo {
      totalEarnedPoints
      activePoints
      spentPoints
      expiredPoints
    }
    userCurrentTier
    dailyProgress
  }
}
`,
    variables: {},
  });
  return postRestApiCall(`${deliveryEndPointGq}`, data, language, site_host);
};

export const getTierList = (ele: any) => {
  const { secondaryArgs = {} } = ele;
  const { prelemBaseEndpoint: { language = "en", deliveryEndPointGq = "", site_host = "" } = {} } =
    secondaryArgs;

  const data = JSON.stringify({
    query: `query {
      users_getTierList{
        tierName
        isActive
        condition {
          attribute
          value
        }
        isDefault
      }
    }`,
    variables: {},
  });
  return postRestApiCall(`${deliveryEndPointGq}`, data, language, site_host);
};

export const getCombinedTireListOLProfile = async (ele) => {
  const [tireListResponse, profileResponse] = await Promise.all([
    getTierList(ele),
    getUserOLProfile(ele),
  ]);
  // Merge the responses
  const mergedResponse = {
    userOLProfile: profileResponse?.data?.data?.users_userOLProfile,
    getTierList: tireListResponse?.data?.data?.users_getTierList,
  };

  return mergedResponse;
};

export const getPointsBreakout = (ele: any) => {
  const { secondaryArgs = {}, userEmail } = ele;
  const { prelemBaseEndpoint: { language = "en", deliveryEndPointGq = "", site_host = "" } = {} } =
    secondaryArgs;

  const data = JSON.stringify({
    query: `query {
  users_fetchMemberTransactions(
    pagination: {start: 1, rows: 10}
    sort: desc
    filter:{
    email: ${JSON.stringify(userEmail)}
      }
  ) {
      event
      pointsEarned
      pointsDeducted
      totalEventCount  
  }
}
`,
    variables: {},
  });
  return postRestApiCall(`${deliveryEndPointGq}`, data, language, site_host);
};

export const getLeaderBoard = (ele: any) => {
  const { secondaryArgs = {}, userEmail, eventName = "" } = ele;
  const { prelemBaseEndpoint: { language = "en", deliveryEndPointGq = "", site_host = "" } = {} } =
    secondaryArgs;

  const data = JSON.stringify({
    query: `query {
    users_getLeaderBoardList(
      campaignName:${JSON.stringify(eventName)}
      userEmail:${JSON.stringify(userEmail)}
    ){
        rank
        userName
        email
        totalPoints
        isCurrentUser
    }
}
`,
    variables: {},
  });
  return postRestApiCall(`${deliveryEndPointGq}`, data, language, site_host);
};

export const getTierByPoints = (state) => {
  if (state?.userOLProfile) {
    const maxVal = state.userOLProfile.userPointsInfo.totalEarnedPoints;
    // Sort tiers by their condition value to handle ranges correctly
    const sortedTiers = state.getTierList.slice().sort((a, b) => {
      return a.condition[0].value - b.condition[0].value;
    });
    // Find the appropriate tier based on maxVal
    for (const tier of sortedTiers) {
      if (maxVal < tier.condition[0].value) {
        return sortedTiers[sortedTiers.indexOf(tier) - 1]?.tierName || sortedTiers[0].tierName;
      }
    }
    // Return the highest tier if all conditions are met
    return sortedTiers[sortedTiers.length - 1].tierName;
  }
};
export const getTierIcon = (state) => {
  const tierName = getTierByPoints(state);
  if (tierName === "Bronze") {
    return BronzeIcon;
  } else if (tierName === "Silver") {
    return SilverIcon;
  } else if (tierName === "Gold") {
    return GoldIcon;
  } else if (tierName === "Platinum") {
    return PlatinumIcon;
  } else {
    return BronzeIcon;
  }
};
export const getInitials = (firstName, lastName) => {
  return `${firstName.charAt(0).toUpperCase()}${lastName.charAt(0)}`.toUpperCase();
};
export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
