import { Avatar, Box, Chip, Typography } from "@mui/material";
import { Coin, EventImgIcon } from "@platformx/shared/static-assets";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserCampaignList } from "../../helperProfile";
import { useCustomStyle } from "./Challanges.style";
import ChallangesSkelton from "./ChallangesSkelton";

interface ChallengeInterface {
  campaignName: string;
  campaignId: string;
  isActive: boolean;
  imgsrc: string;
  pointsEarns: string;
  imageUrl: string;
  destinationUrl: string;
}

const Challenges = ({ secondaryArgs }) => {
  const classes = useCustomStyle();
  const [challanges, setchallanges] = useState<ChallengeInterface[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const getChallangeApi = async () => {
    const res = await getUserCampaignList({
      secondaryArgs: secondaryArgs,
    });
    const { data: { data: { users_getCampaignList = [] } = {} } = {} }: any = res;
    if (users_getCampaignList && users_getCampaignList.length > 0) {
      const activeEvent = users_getCampaignList.filter((item) => item.isActive);
      setchallanges(activeEvent);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  useEffect(() => {
    getChallangeApi();
  }, []);
  const openLinkInNewTab = (link) => {
    window && window.open(link, "_blank", "noopener,noreferrer");
  };
  return (
    <Box className={`${classes.challangesWrapper} challangesWrapperSection gap`}>
      <Typography variant='h4medium' className='noTopMargin'>
        Active Challenges
      </Typography>
      {loading ? (
        <ChallangesSkelton />
      ) : challanges?.length > 0 ? (
        <Box className='challangesList'>
          {challanges?.length > 0 &&
            challanges?.map((item) => (
              <Box
                key={item.campaignId}
                display='flex'
                alignItems='flex-start'
                className='challangeItem'
                onClick={() => openLinkInNewTab(item?.destinationUrl)}>
                <Box className='challangeImage'>
                  <Avatar
                    src={item?.imageUrl ? item?.imageUrl : EventImgIcon}
                    sx={{ width: 68, height: 68, borderRadius: 0 }}></Avatar>
                </Box>

                {/* Right side content */}
                <Box ml={2}>
                  <Chip
                    label={
                      <Typography variant='p4regular'>
                        {item?.pointsEarns ? item?.pointsEarns : "0pts"}
                      </Typography>
                    }
                    className='customchip'
                    icon={<img src={Coin} className='coinImage' alt='coin' />}
                  />
                  {/* Paragraph */}
                  <Typography variant='p4medium' className='noMargin ellipsis-two-lines'>
                    {item?.campaignName}
                  </Typography>
                </Box>
              </Box>
            ))}
        </Box>
      ) : (
        <Box className='noresultFound'>
          <Typography variant='h5regular' className='message'>
            {`${t("no_results_found")}`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Challenges;
