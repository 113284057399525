import { Box, Skeleton } from "@mui/material";

const ChallangesSkelton = () => {
  return [1, 2, 3, 4].map((index) => (
    <Box key={index} display='flex' alignItems='flex-start' className='challengeItem' mb={1}>
      <Box className='challengeImage'>
        <Skeleton variant='circular' width={68} height={68} sx={{ borderRadius: 0 }} />
      </Box>
      <Box ml={2} display='flex' flexDirection='column' justifyContent='center'>
        <Skeleton variant='rectangular' width={100} height={24} />
        <Skeleton variant='text' width={200} height={20} sx={{ marginTop: 1 }} />
      </Box>
    </Box>
  ));
};

export default ChallangesSkelton;
