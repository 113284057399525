import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import InfoCard from "../Component/InfoCard/InfoCard";
import LeaderBoard from "../Component/LeaderBoard/LeaderBoard";
import LinearProgressBar from "../Component/LinearProgressBar/LinearProgressBar";
import ProfileBanner from "../Component/ProfileBanner/ProfileBanner";
import RadialChart from "../Component/RadialChart/RadialChart";
import ShareAndGetPoint from "../Component/ShareAndGetPoint/ShareAndGetPoint";
import { getUserOLProfile } from "../helperProfile";
import { useCustomStyle } from "./Loyalty.style";

const Loyalty = ({ secondaryArgs, profile }) => {
  const classes = useCustomStyle();
  const [userOlData, setUserOlData] = useState<any>(null);
  const getUserOLProfileAPI = async () => {
    if (profile?.member_id) {
      const res = await getUserOLProfile({
        secondaryArgs: secondaryArgs,
        memberId: profile?.member_id,
      });
      const { data: { data: { users_userOLProfile = {} } = {} } = {} } = res;
      setUserOlData(users_userOLProfile);
    }
  };
  useEffect(() => {
    getUserOLProfileAPI();
  }, [profile?.member_id]);

  return (
    <Box className={`${classes.loyaltyTabItem} loyaltyTabSection`}>
      <Grid container>
        <Grid item xs={12} pb={2}>
          <ProfileBanner
            userOlData={userOlData}
            secondaryArgs={secondaryArgs}
            profile={profile}></ProfileBanner>
        </Grid>
        <Grid item xs={12} lg={5} className='leftSection'>
          <Box pb={2}>
            <LinearProgressBar dailyProgress={userOlData?.dailyProgress}></LinearProgressBar>
          </Box>
          <Box pb={2}>
            <RadialChart
              userTotalEarnedPoints={userOlData?.userPointsInfo?.totalEarnedPoints || 0}
              height={286}
              secondaryArgs={secondaryArgs}
              profile={profile}
            />
          </Box>
        </Grid>
        <Grid item xs={12} lg={7} className='rightSection'>
          <LeaderBoard secondaryArgs={secondaryArgs} profile={profile} />
        </Grid>
        <Grid item xs={12} pb={2}>
          <ShareAndGetPoint referalCode={userOlData?.userProfileInfo?.referalCode} />
        </Grid>
        {secondaryArgs?.globalSettings?.loyalty_explained_text ? (
          <Grid item xs={12} pb={2}>
            <InfoCard secondaryArgs={secondaryArgs} />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default Loyalty;
