import {
  Article,
  Awards,
  Challenges_community,
  CommunityIcon,
  contentDefault,
  Course,
  EventIcons,
  Faq,
  FeatureCard,
  Formbuilder30,
  General_community,
  imgIcon,
  News_community,
  Polls,
  quizIcon,
  ServiceCard,
  Shopping_bag,
  Testimonial,
  vodIcon,
} from "@platformx/shared/static-assets";
import { convertToLowerCase, formCroppedUrl } from "@platformx/utilities";

export const getIcon = (ct: string, icon = "", gcpUrl = "", bucketName = "") => {
  if (icon) {
    return formCroppedUrl(gcpUrl, bucketName, icon); //  "https://storage.googleapis.com/cropped_image_public/machine_assets/1729137943540/public/png/Profile.png";
  } else {
    switch (convertToLowerCase(ct)) {
      case "article":
        return Article;
      case "poll":
        return Polls;
      case "quiz":
        return quizIcon;
      case "imagegallery":
        return imgIcon;
      case "event":
        return EventIcons;
      case "faq":
        return Faq;
      case "testimonial":
        return Testimonial;
      case "awards":
        return Awards;
      case "servicecard":
        return ServiceCard;
      case "accolades":
        return FeatureCard;
      case "product":
        return Shopping_bag;
      case "course":
        return Course;
      case "news":
        return News_community;
      case "general":
        return General_community;
      case "challenges-announcement":
        return Challenges_community;
      case "community":
        return CommunityIcon;
      case "vod":
        return vodIcon;
      case "formbuilder":
        return Formbuilder30;
      default:
        return contentDefault;
    }
  }
};

export const replaceWithWebp = (inputString: string) => {
  const lastIndex = inputString?.lastIndexOf(".");
  if (lastIndex === -1) {
    return inputString;
  }
  return inputString?.substring(0, lastIndex) + ".webp";
};

export const getIconWithTitle = (contentList, contentType: string) => {
  const filteredData = contentList.find(
    (content) =>
      content?.isGallery && content?.id?.toLocaleLowerCase() === contentType?.toLocaleLowerCase(),
  );
  return {
    title: filteredData?.title || "",
    icon: filteredData?.icon || "",
    id: filteredData?.id || "",
    fallbackIcon: filteredData?.fallbackIcon || "",
    menuIcon: filteredData?.menuIcon || "",
  };
};
