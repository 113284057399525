import { Box, Grid, Typography } from "@mui/material";
import { useDashboardData } from "@platformx/authoring-apis";
import { RootState } from "@platformx/authoring-state";
import { Charts } from "@platformx/charts";
import { DashboardLoader, NoSearchResult, useUserSession } from "@platformx/utilities";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CardSlider from "./components/cardSlider/CardSlider";
import Title from "./components/common/Title";
import HorizontalCardList from "./components/horizontalCardList/HorizontalCardList";
import RecentCard from "./components/recentCard/RecentCard";
import RecentContent from "./components/recentContent/RecentContent";
import RecentPages from "./components/recentPages/RecentPages";
import ScheduleCardList from "./components/scheduleCardList/ScheduleCardList";
import TaskNotFound from "./components/taskNotFound/TaskNotFound";
import TaskCard from "./components/tasks/taskContent/TaskCard";
import TaskPages from "./components/tasks/taskPages/TasksPages";
import { useStyles } from "./dashboards.styles";

export const Dashboard = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [getSession] = useUserSession();
  const { userInfo } = getSession();
  const {
    // dashBoard,
    deleteContent,
    duplicate,
    edit,
    preview,
    unPublish,
    view,
    fetchDashBoardData,
    fetchContentDetails,
    changeStatus,
    loading,
  } = useDashboardData();
  const { dashBoard } = useSelector((state: RootState) => state.dashboard);
  const { boostContent } = dashBoard || {};

  const taskLength = dashBoard?.taskPages?.length || 0;
  const overDueTaskLength = () => {
    let duetaskCount = 0;
    dashBoard?.taskPages?.forEach((val: any) => {
      if (new Date() > new Date(val?.due_date)) {
        duetaskCount = duetaskCount + 1;
      }
    });
    return duetaskCount;
  };
  const renderRoleBasedDashboard = () => {
    return (
      <Box className={classes.container} key={taskLength}>
        <button
          style={{ display: "none" }}
          type='button'
          onClick={() => {
            throw new Error("Sentry Test Error");
          }}>
          Break the world
        </button>
        <Box sx={{ display: { xs: "block", em: "flex" } }}>
          <Typography color='activeColor' variant='h1bold' data-testid='greeting-message' mr={0.5}>
            {`${t("greets_x")}  `}
          </Typography>
          <Typography variant='h1bold' data-testid='username'>
            {" "}
            {userInfo?.name}
          </Typography>
        </Box>
        {/* #TODO Commenting for X-Launch */}
        <Typography variant='h6medium' mt='5px' mb='10px'>
          {taskLength > 0 && ` ${t("you_have")} ${taskLength} ${t("new_task")}`}{" "}
          {overDueTaskLength() > 0 &&
            `${t("and")} ${overDueTaskLength()} ${t("overdue_task_text")}`}
        </Typography>
        {/* Page And Content section */}
        <Box className={classes.sectionMargin}>
          <Grid container>
            <Grid item xs={12} md={12} em={12} lg={12} sx={{ paddingRight: { xs: 0, lg: 0 } }}>
              <TaskCard
                title={t("tasks")}
                titleVariant='h5semibold'
                refetch={false}
                refetchFunction={fetchDashBoardData}
                refetchLoading={loading}>
                <Box>
                  {loading ? (
                    <DashboardLoader />
                  ) : dashBoard?.taskPages?.length > 0 ? (
                    <TaskPages
                      taskPages={dashBoard?.taskPages}
                      fetchDashBoardData={fetchDashBoardData}
                      changeStatus={changeStatus}
                      edit={edit}
                    />
                  ) : dashBoard?.taskPages?.length === 0 || dashBoard?.taskPages === null ? (
                    <TaskNotFound />
                  ) : (
                    <DashboardLoader />
                  )}
                </Box>
              </TaskCard>
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.sectionMargin}>
          <Grid container>
            <Grid item xs={12} md={12} em={12} lg={8} sx={{ paddingRight: { xs: 0, lg: "20px" } }}>
              <RecentCard
                title={t("recent_pages")}
                titleVariant='h5semibold'
                linkText={t("view_more")}>
                {dashBoard?.recentPages?.length > 0 ? (
                  <RecentPages recentPages={dashBoard?.recentPages || []} />
                ) : dashBoard?.recentPages?.length === 0 ? (
                  <NoSearchResult />
                ) : (
                  <DashboardLoader />
                )}
              </RecentCard>
            </Grid>
            <Grid item xs={12} md={12} em={12} lg={4} sx={{ marginTop: { xs: "20px", lg: "0" } }}>
              <RecentCard title={t("recent_content")} titleVariant='h5semibold'>
                {dashBoard?.recentContent?.length > 0 ? (
                  <RecentContent
                    deleteContent={deleteContent}
                    duplicate={duplicate}
                    edit={edit}
                    unPublish={unPublish}
                    view={view}
                    preview={preview}
                    recentContent={dashBoard?.recentContent || []}
                    fetchContentDetails={fetchContentDetails}
                  />
                ) : dashBoard?.recentContent?.length === 0 ? (
                  <NoSearchResult />
                ) : (
                  <DashboardLoader />
                )}
              </RecentCard>
            </Grid>
          </Grid>
        </Box>

        {/* Start slider code here */}
        <Box className={classes.cardMargin}>
          <Box className={classes.cardText} pl='10px'>
            <Title titleVarient='h5semibold' title={`${userInfo?.name}, ${t("to_create")}`} />
          </Box>
          <Box className='cardslider ml-m-15 mr-m-15'>
            <CardSlider loading={loading} createContent={dashBoard?.createContent} />
          </Box>
        </Box>

        {/* Boost your page section  */}
        <Box className={classes.boostMargin}>
          <Box className={classes.cardText}>
            <Title titleVarient='h5semibold' title={t("boost_pages")} />
          </Box>
          <HorizontalCardList loading={loading} boostContent={boostContent} />
        </Box>

        {/* Your Scheduled Items */}
        {(dashBoard?.scheduled?.length || 0) > 0 && (
          <Box className={classes.sectionMargin}>
            <Box className={classes.textMargin}>
              <Title titleVarient='h5semibold' title={t("scheduled_items")} />
            </Box>
            <ScheduleCardList scheduledPages={dashBoard?.scheduled} />
          </Box>
        )}
        <Suspense fallback={<Typography variant='h5semibold'>Loading...</Typography>}>
          <Box className='snapshortsChart'>
            <Charts
              dashboardName='reportSnapshot'
              titleVarient='h5semibold'
              heading={t("report_snapshot")}
            />
          </Box>
        </Suspense>
      </Box>
    );
  };

  return <div>{renderRoleBasedDashboard()}</div>;
};
