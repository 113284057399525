/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, FormControl, useTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import { dynamicAPICall } from "../../../utils/helperFns";
import HiddenTextBox from "../HiddenTextBox/HiddenTextBox";
import "./AutoCompleteDropDown.css";

const icon = <CheckBoxOutlineBlankIcon fontSize='small' sx={{ color: "#14142B" }} />;
const checkedIcon = <CheckBoxIcon fontSize='small' sx={{ color: "#14142B" }} />;
export type AutoCompleteMultiSelectProps = {
  options: any;
  state?: any;
  optionFormat?: string;
  field?: any;
  error: any;
  register: any;
  handleChangeOn?: any;
};
export const AutoCompleteDropDown = ({
  options,
  state = [],
  optionFormat = "",
  field,
  error,
  register,
  handleChangeOn,
}: AutoCompleteMultiSelectProps) => {
  const { placeholder = "", multiple = true, dependent_name = "" } = field || {};
  const theme = useTheme();
  const [optionsList, setOptionsList] = useState(options || []);
  const handleChange = (_: any, newValue) => {
    handleChangeOn(newValue, field, multiple);
  };
  const [defaultValues, setDefaultValues] = React.useState<any>([]);

  useEffect(() => {
    if (state[field.name] && optionsList?.length > 0) {
      const defaultValue = multiple
        ? state[field.name]?.filter((x) => optionsList?.some((y) => y.label === x.label))
        : optionsList.find((x) => x.label === state[field.name][0]?.label) || null;
      if (defaultValue) setDefaultValues(defaultValue);
    }
  }, [state[field.name], optionsList]);

  useEffect(() => {
    // Extract onload event and query
    const onloadGraphqlEvent = field?.events?.find(
      (x) => x.event === "onload" && x.type === "graphql",
    );
    if (onloadGraphqlEvent) {
      const { query = "", responseVariable = "" } = onloadGraphqlEvent;
      if (query && responseVariable) {
        dynamicAPICall(query, responseVariable).then((result) => {
          setOptionsList(result || []);
        });
      }
    }
  }, []);

  useEffect(() => {
    setOptionsList(options);
  }, [options]);

  return (
    <Box id={`${field?.name}_id`}>
      <FormControl fullWidth error={error}>
        <Autocomplete
          value={defaultValues}
          popupIcon={<ExpandMoreIcon />}
          className='inviteMembersAutoComplete'
          multiple={multiple}
          id='checkboxesdemos'
          isOptionEqualToValue={(option: any, value) => {
            return option?.[optionFormat] === value?.[optionFormat];
          }}
          options={optionsList}
          disabled={dependent_name && !state[dependent_name]}
          disableCloseOnSelect={multiple}
          onChange={handleChange}
          classes={{
            popper: "autocompleteMultiSelectPopper",
            paper: "autocompleteMultiSelectPapper",
            inputRoot: "autocompleteMultiSelectInputRoot",
            tag: "autocompleteMultiSelectTag",
          }}
          renderOption={(props, option: any, { selected }) => (
            <li {...props}>
              {multiple ? (
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
              ) : null}
              {option[optionFormat]}
            </li>
          )}
          renderInput={(params) => {
            return (
              <TextField
                variant='filled'
                style={{
                  border: error ? `solid 1px ${theme.palette.customColor.ERROR}` : ``,
                  background: error ? "#FFEBEE" : "transparent",
                  borderRadius: "5px",
                }}
                {...params}
                placeholder={placeholder}
              />
            );
          }}
        />
      </FormControl>
      <HiddenTextBox
        error={error}
        value={state[field?.name]?.length || ""}
        register={register}
        name={field?.name}
        required={!state[field?.name]?.length ? field?.validations[0]?.message : ""}
      />
    </Box>
  );
};
