import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    userProfilePageMenu: {
      "&.profileMenuSection": {
        position: "relative",
        minHeight: "30px",
        "& .humburgerMenu": {
          // position: "absolute",
          // top: "-15px",
          cursor: "pointer",
        },
        "& .profileDrawerMenu": {
          background: theme.palette.prelemType1.BACKGROUND,
          border: `solid 1px ${theme.palette.prelemType1.LINE}`,
          padding: "20px",
          "&.smallScreen": {
            border: "none",
            borderBottom: `solid 1px ${theme.palette.prelemType1.LINE}`,
            padding: 0,
            marginBottom: "16px",
            "& .profile-menu": {
              "& .Platform-x-TabScrollButton-horizontal": {
                display: "none",
                // "& svg": {
                //   fontSize: "24px",
                //   border: `solid 1px ${theme.palette.prelemType1.LINE}`,
                //   borderRadius: "50%",
                //   color: theme.palette.prelemType1.TITLE,
                // },
                // "&.Mui-disabled": {
                //   opacity: "0.3 !important",
                // },
              },
              "& .profile-menu-item": {
                width: "auto",
                minWidth: "auto",
                marginRight: "30px",
                "&.Mui-disabled": {
                  opacity: 0.5,
                },
              },
            },
          },
          "& .heading": {
            borderBottom: `solid 1px ${theme.palette.prelemType1.LINE}`,
            padding: "12px 0px",
            marginTop: 0,
            paddingTop: 0,
          },
          "& .profile-menu": {
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
            "& .Platform-x-Tabs-scroller": {
              "& .Platform-x-Tabs-indicator": {
                display: "none",
              },
            },
            "& .profile-menu-item": {
              border: "none",
              justifyContent: "left",
              padding: 0,
              minHeight: "50px",
              color: theme.palette.prelemType1.TITLE,
              "&.Mui-disabled": {
                opacity: 0.5,
              },
              "&.Mui-selected": {
                fontWeight: "700 !important",
                color: `${theme.palette.prelemType1.TITLE} !important`,
                "& span": { display: "none" },
              },
              "& img": {
                width: "20px",
                marginRight: "10px",
              },
            },
          },
        },
      },
    },
  };
});
