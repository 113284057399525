import { Box, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SkeletonLoader } from "../../../components/SkeletonLoader/SkeletonLoader";
import ToastService from "../../../components/ToastContainer/ToastService";
import { ecomCartIdBasedGetItem } from "../helperEcommerce";
import ProductListSlider from "../ProductDetail/ProductListSlider";
import OrderActions from "./OrderActions/OrderActions";
import OrderDetails from "./OrderDetails/OrderDetails";
import OrderHighlights from "./OrderHighlights/OrderHighlights";
import { useCustomStyle } from "./OrderPlaced.style";
import OrderRecived from "./OrderRecived/OrderRecived";
import OrderRewards from "./OrderRewards/OrderRewards";

const OrderPlaced = ({ secondaryArgs = {} }) => {
  const classes = useCustomStyle();
  const { t } = useTranslation();
  const [orderDetail, setOrderDetail] = useState(null);
  const [loader, setLoader] = useState(true);
  const [personaliseItem, setPersonaliseItem] = useState({ product_id: "", email: "" });

  useEffect(() => {
    const url = new URL(window.location.href);
    const getCartIdFromLocal =
      localStorage.getItem("ecommerceCartId") || url.searchParams.get("cart_id");
    localStorage.removeItem("ecommerceCartId");
    if (getCartIdFromLocal) {
      cartItemDetails(getCartIdFromLocal);
    } else {
      setOrderDetail(null);
      ToastService.failToast(t("errorRequest"));
    }
  }, []);

  const cartItemDetails = async (cartId = "") => {
    try {
      const response = await ecomCartIdBasedGetItem({
        cartId: cartId,
        secondaryArgs: secondaryArgs,
      });
      const { data: { data: { getCartItems: { statusCode = 0, data = {} } = {} } = {} } = {} } =
        response;
      if (statusCode === 200) {
        setOrderDetail(data);
        setPersonaliseItem({
          product_id: data?.line_item[0]?.product_id,
          email: data?.shipping_address?.email,
        });
        setLoader(false);
      } else {
        setOrderDetail(null);
        ToastService.failToast(t("errorRequest"));
      }
    } catch (err) {
      setOrderDetail(null);
      ToastService.failToast(t("errorRequest"));
    }
  };
  return (
    <Box className={`${classes.orderPlaced} orderPlacedSection`}>
      {loader ? (
        <Box sx={{ minHeight: "100vh" }}>
          <SkeletonLoader />
        </Box>
      ) : (
        <Container className='grid_container prelem-py grid_container_nopadding'>
          {orderDetail ? (
            <>
              <Grid item xs={12} alignItems='stretch' columnSpacing={2} className='orderPlacedRow'>
                <Grid container>
                  <Grid item xs={12} md={12} em={5} lg={6}>
                    <OrderRecived orderDetail={orderDetail} />
                  </Grid>
                  <Grid item xs={12} md={6} em={4} lg={4}>
                    <OrderRewards secondaryArgs={secondaryArgs} />
                  </Grid>
                  <Grid item xs={12} md={6} em={3} lg={2}>
                    <OrderActions />
                  </Grid>
                </Grid>
                <OrderHighlights orderDetail={orderDetail} />
              </Grid>
              <Grid item xs={12} className='orderDetailRow'>
                <OrderDetails orderDetail={orderDetail} />
              </Grid>
              <Grid item xs={12} className='crosSellRow'>
                <ProductListSlider
                  title={t("you_might_be_also_interested_in")}
                  productId={personaliseItem?.product_id}
                  secondaryArgs={secondaryArgs}
                  selectedPersonalisation={{ "personalisation-value": "crosssellproducts" }}
                />
                <ProductListSlider
                  title={t("you_may_also_like")}
                  productId={personaliseItem?.product_id}
                  secondaryArgs={secondaryArgs}
                  selectedPersonalisation={{ "personalisation-value": "upsellproducts" }}
                />
                {personaliseItem?.email ? (
                  <ProductListSlider
                    title={t("recent_viewed")}
                    secondaryArgs={secondaryArgs}
                    selectedPersonalisation={{ "personalisation-value": "repeatedproducts" }}
                  />
                ) : (
                  <ProductListSlider
                    title={t("related_products")}
                    productId={personaliseItem?.product_id}
                    secondaryArgs={secondaryArgs}
                    selectedPersonalisation={{ "personalisation-value": "relatedproducts" }}
                  />
                )}
              </Grid>
            </>
          ) : null}
        </Container>
      )}
    </Box>
  );
};

export default OrderPlaced;
