import { Box, Skeleton } from "@mui/material";

const LoyaltySkeleton = () => {
  return (
    <Box sx={{ width: "100%", height: 90, padding: "0px 8px" }}>
      {/* Container for Image and Right Side Skeletons */}
      <Box display='flex' alignItems='center' height='100%'>
        {/* Left Side Image Skeleton */}
        <Skeleton variant='rectangular' width={70} height={65} />

        {/* Right Side Labels and Skeletons */}
        <Box sx={{ flexGrow: 1, marginLeft: 2 }}>
          {/* Two Top Labels */}
          <Box display='flex' justifyContent='space-between' alignItems='center' mb={0.5}>
            <Skeleton variant='text' width={100} height={15} />
            <Skeleton variant='text' width={100} height={15} />
          </Box>

          {/* Full Row Text Skeleton */}
          <Skeleton variant='text' width='100%' height={15} sx={{ mb: 1 }} />

          {/* Three Aligned Labels */}
          <Box display='flex' justifyContent='space-between'>
            <Skeleton variant='text' width={80} height={15} />
            <Skeleton variant='text' width={80} height={15} />
            <Skeleton variant='text' width={80} height={15} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LoyaltySkeleton;
