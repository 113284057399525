import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    orderPlaced: {
      "&.orderPlacedSection": {
        "& .orderPlacedRow": {
          background: theme.palette.prelemType3.BACKGROUND,
          padding: "20px",
          "& .orderRecivedColumn": {
            [theme.breakpoints.up("em")]: {
              paddingRight: "40px",
            },
            [theme.breakpoints.between("md", "em")]: {
              paddingBottom: "24px",
              marginBottom: "24px",
              borderBottom: `solid 1px ${theme?.palette?.prelemType3?.LINE}`,
            },
            "& .orderedProduct": {
              width: 92,
              height: 92,
              backgroundColor: "gray",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },

            "& .slideshow-container": {
              "& .mySlides": {
                padding: "0 0 0 30px",
              },
              "& .inside.prev, & .inside.next": {
                top: "50%",
                width: "25px",
                height: "25px",
                padding: "0px",
                paddingBottom: "0px",
                fontWeight: "normal",
                borderRadius: "50%",
                border: `solid 1px ${theme?.palette?.prelemType3?.TITLE}`,
                "& svg": {
                  fontSize: "22px",
                  paddingTop: "2px",
                },
                "&:hover": {
                  border: `solid 1px ${theme?.palette?.prelemType3?.LINK}`,
                },
              },
            },
          },
          "& .orderRewardColumn": {
            [theme.breakpoints.down("md")]: {
              borderTop: `solid 1px ${theme?.palette?.prelemType3?.LINE}`,
              borderBottom: `solid 1px ${theme?.palette?.prelemType3?.LINE}`,
              padding: "24px 0px",
              margin: "24px 0px",
            },

            [theme.breakpoints.between("md", "em")]: {
              borderRight: `solid 1px ${theme?.palette?.prelemType3?.LINE}`,
              height: "100%",
              paddingRight: "20px",
            },
            [theme.breakpoints.up("em")]: {
              height: "100%",
              padding: "0 24px",
              borderLeft: `solid 1px ${theme?.palette?.prelemType3?.LINE}`,
              borderRight: `solid 1px ${theme?.palette?.prelemType3?.LINE}`,
            },
            "& .starIcon": {
              width: 30,
              height: 30,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            },
            "& .viewAll": {
              cursor: "pointer",
              minWidth: "90px",
              justifyContent: "right",
              "& svg": {
                fontSize: "18px",
                paddingTop: "2px",
              },
            },
          },
          "& .orderActionColumn": {
            [theme.breakpoints.up("md")]: {
              paddingLeft: "20px",
            },
          },
        },
        "& .orderDetailRow": {
          background: theme.palette.prelemType1.BACKGROUND,
          padding: "20px",
          paddingBottom: 0,
          [theme.breakpoints.up("md")]: {
            borderBottom: `solid 1px ${theme?.palette?.prelemType1?.LINE}`,
          },
          "& .productOrderDetail": {
            "& tr:last-child th,& tr:last-child td": {
              border: "none",
            },
          },
          "& .address": {
            [theme.breakpoints.up("md")]: {
              marginLeft: theme.spacing(7),
              borderLeft: `solid 1px ${theme?.palette?.prelemType1?.LINE}`,
            },
            height: "100%",
            "& .subheading": {
              padding: 0,
              textTransform: "capitalize",
              marginTop: "16px",
            },
            "& .description": {
              marginTop: "8px",
            },
            "& .shippingAddress": {
              [theme.breakpoints.up("md")]: {
                paddingLeft: theme.spacing(4),
                paddingBottom: theme.spacing(2),
              },
            },
            "& .billingAddress": {
              borderTop: `solid 1px ${theme?.palette?.prelemType1?.LINE}`,
              marginTop: theme.spacing(2),
              [theme.breakpoints.up("md")]: {
                marginTop: 0,
                paddingLeft: theme.spacing(4),
                paddingBottom: theme.spacing(2),
              },
            },
          },
        },
        "& .crosSellRow": {
          marginTop: theme.spacing(2),
        },
        "& .noMargin": {
          margin: 0,
        },
        "& .noTopMargin": {
          marginTop: 0,
        },
        "& .noBottomMargin": {
          marginTop: 0,
        },
        "& .labelColor": {
          color: `${theme.palette.prelemType1.LABEL}`,
        },
      },
    },
  };
});
