import {
  ApprovalStatus,
  ArticleIcon,
  Awards,
  BookIcon,
  Challenges_community,
  CookieIcon,
  Course,
  EcomIcon,
  EventsIcon,
  Faq,
  FeatureCard,
  FooterMenuUpdated,
  FormbuilderIcon,
  GallerySelectIcon,
  General_community,
  GlobalSetting as GlobalSettingIcon,
  GroupUsersIcon,
  HamburgerMenuIcon,
  HeaderMenuUpdated,
  LoyaltyIcon,
  MediaIcon,
  MyDashboardIcon,
  News_community,
  PagesIcon,
  PollIcon,
  QuizIcon,
  ServiceCard,
  Shopping_bag,
  SitesIcon,
  TagIcon,
  Testimonial,
  UserEngagmentIcon,
  UsersIcon,
  VODIcon,
  VideoGallerySelectIcon,
  VideoNav,
  Webmaster,
  communitySpaceIcon,
} from "@platformx/shared/static-assets";

export const existingRoutes = [
  {
    title: "Overview",
    // icon needs to be move to gcp
    icon: MyDashboardIcon,
    url: "/dashboard",
    id: "overview",
    category: "dashboard",
    subCategory: "",
    component: "dashboard",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    title: "Page",
    icon: PagesIcon,
    url: "/sitepage",
    id: "SitePage",
    category: "page",
    subCategory: "SitePage",
    component: "content",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: true,
  },
  {
    title: "Article",
    icon: ArticleIcon,
    url: "/content/article",
    id: "Article",
    category: "content",
    subCategory: "article",
    component: "content",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: true,
    hasSearch: true,
  },
  {
    title: "VOD",
    icon: VODIcon,
    url: "/content/vod",
    category: "content",
    subCategory: "vod",
    id: "Vod",
    component: "content",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: true,
    hasSearch: true,
  },
  {
    title: "Quiz",
    icon: QuizIcon,
    url: "/content/quiz",
    category: "content",
    subCategory: "quiz",
    id: "Quiz",
    component: "content",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: true,
    hasSearch: true,
  },
  {
    title: "Poll",
    icon: PollIcon,
    url: "/content/poll",
    category: "content",
    subCategory: "poll",
    id: "Poll",
    component: "content",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: true,
    hasSearch: true,
  },
  {
    title: "Event",
    icon: EventsIcon,
    url: "/content/event",
    category: "content",
    subCategory: "event",
    id: "Event",
    component: "content",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: true,
    hasSearch: true,
  },
  {
    title: "Form",
    icon: FormbuilderIcon,
    url: "/content/formbuilder",
    category: "content",
    subCategory: "Formbuilder",
    id: "Formbuilder",
    component: "content",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: true,
    hasSearch: true,
  },
  {
    title: "Course",
    icon: BookIcon,
    url: "/content/course",
    category: "content",
    subCategory: "course",
    id: "course",
    component: "content",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    title: "User Engagement",
    icon: UserEngagmentIcon,
    url: "/reports/user-engagement",
    id: "user_engagement",
    category: "reports",
    subCategory: "",
    component: "userEngagement",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    title: "Site Performance",
    icon: Webmaster,
    url: "/reports/site-performance",
    id: "site_performance",
    category: "reports",
    subCategory: "SitePerformance",
    component: "sitePerformance",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    title: "Ecommerce",
    icon: EcomIcon,
    url: "/reports/ecommerce",
    id: "ecommerce",
    category: "reports",
    subCategory: "Ecommerce",
    component: "ecommerce",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    title: "Customer Loyalty",
    icon: LoyaltyIcon,
    url: "/reports/loyalty",
    id: "customer_loyalty",
    category: "reports",
    subCategory: "CustomerLoyalty",
    component: "loyalty",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },

  {
    title: "Community",
    icon: communitySpaceIcon,
    url: "/community/space",
    id: "community_space",
    category: "Community",
    subCategory: "spaces",
    component: "space",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    title: "User Groups",
    icon: GroupUsersIcon,
    url: "/community/user-groups",
    id: "user_group",
    category: "Community",
    subCategory: "spaces",
    component: "userGroupListing",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    title: "User",
    icon: UsersIcon,
    url: "/user-management/user-list",
    id: "user",
    category: "UserManagement",
    subCategory: "users",
    component: "userListing",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    title: "Workflow Management",
    icon: ApprovalStatus,
    url: "/workflow/workflow-list",
    id: "workflow_management",
    category: "Workflow",
    subCategory: "",
    component: "workflowManagement",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },

  {
    title: "Menu",
    icon: HamburgerMenuIcon,
    url: "/navtree",
    id: "menu",
    category: "Menu",
    subCategory: "",
    component: "navTreeCreation",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },

  {
    title: "Image",
    icon: MediaIcon,
    url: "/asset/images",
    id: "image",
    category: "Assets",
    subCategory: "",
    component: "assetListing",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    title: "Video",
    icon: VideoNav,
    url: "/asset/videos",
    id: "video",
    category: "Assets",
    subCategory: "",
    component: "assetListing",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },

  {
    title: "Header Setting",
    icon: HeaderMenuUpdated,
    url: "/site-setting/header-setting",
    id: "header_setting",
    category: "SiteSetting",
    subCategory: "HeaderSetting",
    component: "headerSetting",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    title: "Footer Setting",
    icon: FooterMenuUpdated,
    url: "/site-setting/footer-setting",
    id: "Footer_Setting",
    category: "SiteSetting",
    subCategory: "FooterSetting",
    component: "footerSetting",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    title: "Cookie Policy",
    icon: CookieIcon,
    url: "/site-setting/cookie-setting",
    id: "cookie_policy",
    category: "SiteSetting",
    subCategory: "CookieSetting",
    component: "cookieSetting",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    title: "Media Handle",
    icon: MediaIcon,
    url: "/site-setting/media-handle",
    id: "media_handle",
    category: "SiteSetting",
    subCategory: "MediaHandle",
    component: "mediaHandle",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    title: "Global Setting",
    icon: GlobalSettingIcon,
    url: "/site-setting/global-setting",
    id: "global_setting",
    category: "SiteSetting",
    subCategory: "GlobalSetting",
    component: "globalSetting",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    title: "Tag",
    icon: TagIcon,
    url: "/site-setting/tags",
    id: "tag",
    category: "SiteSetting",
    subCategory: "Tag",
    component: "tagListing",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },

  {
    title: "Sites",
    icon: SitesIcon,
    url: "/sites/site-listing",
    id: "Sites",
    category: "Site",
    subCategory: "Sites",
    component: "siteListing",
    isMenu: true,
    isHidden: false,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },

  {
    id: "dashboard",
    url: "/",
    category: "dashboard",
    subCategory: "",
    icon: "",
    title: "dashboard",
    isMenu: false,
    isHidden: true,
    component: "dashboard",
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "search_results",
    url: "/search-results",
    category: "public",
    subCategory: "public",
    isMenu: false,
    icon: "",
    title: "public",
    component: "content",
    isHidden: true,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "workflow_details",
    url: "/workflow/workflow-details",
    category: "Workflow",
    subCategory: "",
    isMenu: false,
    icon: "",
    title: "",
    component: "workflowDetails",
    isHidden: true,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "create_user",
    url: "/user-management/user-create",
    category: "UserManagement",
    subCategory: "",
    isMenu: false,
    icon: "",
    title: "",
    component: "createUser",
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "create_content",
    url: "/content/:contentType",
    category: "content",
    subCategory: "quiz",
    isMenu: false,
    icon: "",
    title: "quiz",
    component: "createContent",
    isHidden: true,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "create_collection",
    url: "/collection/:contentType",
    category: "collection",
    subCategory: "",
    isMenu: false,
    icon: "",
    title: "",
    component: "createContent",
    isHidden: true,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "create_space",
    url: "/community/create-space",
    category: "public",
    subCategory: "public",
    isMenu: false,
    icon: "",
    title: "public",
    component: "createSpace",
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "content",
    url: "/content/common/preview",
    category: "content",
    subCategory: "Article",
    isMenu: false,
    icon: "",
    title: "Article",
    component: "commonContentRender",
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "create_course",
    url: "/content/create-course",
    category: "content",
    subCategory: "",
    isMenu: false,
    icon: "",
    title: "",
    component: "createCourse",
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "create_asset",
    url: "/asset/create/:taskTitle",
    category: "Assets",
    subCategory: "",
    isMenu: false,
    icon: "",
    title: "",
    component: "createAsset",
    isHidden: true,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "feature_flag",
    url: "/site-setting/feature-flag",
    category: "SiteSetting",
    subCategory: "GlobalSetting",
    isMenu: false,
    icon: "",
    title: "GlobalSetting",
    component: "featureFlagSetting",
    isHidden: true,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },

  {
    id: "tag_details",
    url: "/site-setting/tags/:category",
    category: "SiteSetting",
    subCategory: "Tag",
    isMenu: false,
    icon: "",
    title: "Tag",
    component: "categoryDetail",
    isHidden: true,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "create_tags",
    url: "/site-setting/create-tags",
    category: "SiteSetting",
    subCategory: "Tag",
    isMenu: false,
    icon: "",
    title: "Tag",
    component: "createTags",
    isHidden: true,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "update_tags",
    url: "/site-setting/create-tags/:docPath",
    category: "SiteSetting",
    subCategory: "Tag",
    isMenu: false,
    icon: "",
    title: "Tag",
    component: "createTags",
    isHidden: true,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "create_site",
    url: "/sites/site-creation",
    category: "site",
    subCategory: "Sites",
    isMenu: false,
    icon: "",
    title: "Sites",
    component: "addSite",
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "update_site",
    url: "/sites/site-creation/:siteName",
    category: "site",
    subCategory: "Sites",
    isMenu: false,
    icon: "",
    title: "Sites",
    component: "addSite",
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "create_article",
    url: "/content/create-article",
    category: "content",
    subCategory: "article",
    isMenu: false,
    icon: "",
    title: "article",
    component: "createArticle",
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "update_user_group",
    url: "/create/user-groups",
    category: "community",
    subCategory: "",
    isMenu: false,
    icon: "",
    title: "",
    component: "createUserGroup",
    isHidden: true,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "create_user_group",
    url: "/update/user-groups",
    category: "community",
    subCategory: "",
    isMenu: false,
    icon: "",
    title: "",
    component: "createUserGroup",
    isHidden: true,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "time_line_blogs",
    url: "/content/create-blog",
    category: "public",
    subCategory: "public",
    isMenu: false,
    icon: "",
    title: "public",
    component: "timeLineBlogs",
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "search_prelem",
    url: "/prelem-search",
    category: "page",
    subCategory: "",
    isMenu: false,
    icon: "",
    title: "",
    component: "searchPrelem",
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "formbuilder",
    url: "/content/create-formbuilder",
    category: "page",
    subCategory: "",
    isMenu: false,
    icon: "",
    title: "",
    component: "formBuilder",
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "prelem_info",
    url: "/prelem-search/about",
    category: "page",
    subCategory: "",
    isMenu: false,
    icon: "",
    title: "",
    component: "prelemInfo",
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "prelem",
    url: "/prelem-search/preview",
    category: "page",
    subCategory: "",
    isMenu: false,
    icon: "",
    title: "",
    component: "prelemPreview",
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "page",
    url: "/preview-page/:device",
    category: "page",
    subCategory: "",
    isMenu: false,
    icon: "",
    title: "",
    component: "pagePreview",
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "edit_page",
    url: "/edit-page",
    category: "page",
    subCategory: "",
    isMenu: false,
    icon: "",
    title: "",
    component: "editPage",
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "access_denied",
    url: "/access-denied",
    category: "",
    subCategory: "",
    isMenu: false,
    icon: "",
    title: "",
    component: "accessDenied",
    isHidden: true,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "quiz",
    url: "/contentcreate/:contentType",
    category: "content",
    subCategory: "quiz",
    isMenu: false,
    icon: "",
    title: "",
    component: "dynamicComponentContainer",
    isHidden: true,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  {
    id: "quiz",
    url: "/contentupdate/:contentType/:documentId",
    category: "content",
    subCategory: "quiz",
    isMenu: false,
    icon: "",
    title: "",
    component: "dynamicComponentContainer",
    isHidden: true,
    isSideBar: true,
    isHeader: true,
    isGallery: false,
    hasSearch: false,
  },
  //gallery items
  {
    icon: GallerySelectIcon,
    title: "Gallery",
    component: "",
    id: "Gallery",
    url: "",
    category: "",
    subCategory: "",
    isMenu: false,
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: true,
    hasSearch: false,
  },
  {
    icon: VideoGallerySelectIcon,
    title: "Video Gallery",
    component: "",
    id: "VideoGallery",
    url: "",
    category: "",
    subCategory: "",
    isMenu: false,
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: true,
    hasSearch: false,
  },
  {
    icon: GallerySelectIcon,
    title: "Image Gallery",
    component: "",
    id: "ImageGallery",
    url: "",
    category: "",
    subCategory: "",
    isMenu: false,
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: true,
    hasSearch: false,
  },
  {
    icon: Faq,
    title: "Faq",
    component: "",
    id: "FAQ",
    url: "",
    category: "",
    subCategory: "",
    isMenu: false,
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: true,
    hasSearch: false,
  },
  {
    icon: Testimonial,
    title: "Testimonial",
    component: "",
    id: "Testimonial",
    url: "",
    category: "",
    subCategory: "",
    isMenu: false,
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: true,
    hasSearch: false,
  },
  {
    icon: Awards,
    title: "Awards",
    component: "",
    id: "Awards",
    url: "",
    category: "",
    subCategory: "",
    isMenu: false,
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: true,
    hasSearch: false,
  },
  {
    icon: ServiceCard,
    title: "Service Card",
    component: "",
    id: "ServiceCard",
    url: "",
    category: "",
    subCategory: "",
    isMenu: false,
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: true,
    hasSearch: false,
  },
  {
    icon: FeatureCard,
    title: "Accolades",
    component: "",
    id: "accolades",
    url: "",
    category: "",
    subCategory: "",
    isMenu: false,
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    icon: Shopping_bag,
    title: "Product",
    component: "",
    id: "product",
    url: "",
    category: "",
    subCategory: "",
    isMenu: false,
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: true,
    hasSearch: false,
  },
  {
    icon: Course,
    title: "Course",
    component: "",
    id: "course",
    url: "",
    category: "",
    subCategory: "",
    isMenu: false,
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    icon: News_community,
    title: "News community",
    component: "",
    id: "news",
    url: "",
    category: "",
    subCategory: "",
    isMenu: false,
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    icon: General_community,
    title: "General community",
    component: "",
    id: "general",
    url: "",
    category: "",
    subCategory: "",
    isMenu: false,
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    icon: Challenges_community,
    title: "Challenges announcement",
    component: "",
    id: "challenges-announcement",
    url: "",
    category: "",
    subCategory: "",
    isMenu: false,
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    title: "Preview",
    icon: "",
    url: "/content/preview",
    id: "content",
    category: "content",
    subCategory: "quiz",
    component: "contentPreview",
    isMenu: false,
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  {
    title: "prelem",
    icon: "",
    url: "/prelem",
    id: "content",
    category: "content",
    subCategory: "quiz",
    component: "prelemComponent",
    isMenu: false,
    isHidden: true,
    isSideBar: false,
    isHeader: false,
    isGallery: false,
    hasSearch: false,
  },
  // {
  //   icon: Community,
  //   title: "Community",
  //   component: "",
  //   id: "community",
  //   url: "",
  //   category: "",
  //   subCategory: "",
  //   isMenu: false,
  //   isHidden: true,
  //   isSideBar: false,
  //   isHeader: false,
  //   isGallery: false,
  //   hasSearch: false,
  // },
];
