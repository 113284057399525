import { PROJECT_CATCH_ERROR_LIST } from "@platformx/utilities";
import graphqlInstance from "../../config/graphqlConfig";
import {
  CREATE_CONTENT_TYPE,
  PUBLISH_CONTENT,
  PUBLISH_CONTENT_TYPE,
  SAVE_CONTENT_TYPE,
  UPDATE_CONTENT,
} from "../../graphQL/mutations/contentTypeMutations";
import { schemaQueries } from "../../graphQL/queries/contentTypeQueries";
import { ApiResponse } from "../../utils/types";

const contentTypeSchemaApi = {
  getSchemaDynamic: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: schemaQueries.CONTENT_SCHEMA,
        fetchPolicy: "no-cache",
        variables: input,
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:getSchema`, err);
      return err?.response?.data || {};
    }
  },
  getSchemaDynamicWithData: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: schemaQueries.CONTENT_SCHEMA_WITH_DATA,
        fetchPolicy: "no-cache",
        variables: input,
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:getSchema`, err);
      return err?.response?.data || {};
    }
  },
  saveContent: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: SAVE_CONTENT_TYPE,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:saveContent`, err);
      return err?.response?.data || {};
    }
  },
  updateContent: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: UPDATE_CONTENT,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:saveContent`, err);
      return err?.response?.data || {};
    }
  },
  publishContentType: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: PUBLISH_CONTENT,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:publishContent`, err);
      return err?.response?.data || {};
    }
  },
  //start: old apis need to check profile and delte
  getSchema: async <T>(): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: schemaQueries.CONTENT_TYPE_SCHEMA,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:getSchema`, err);
      throw err;
    }
  },
  createContent: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: CREATE_CONTENT_TYPE,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:createContent`, err);
      throw err;
    }
  },
  publishContent: async <T>(input: any): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: PUBLISH_CONTENT_TYPE,
        variables: input,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:publishContent`, err);
      throw err;
    }
  },
  fetchSchema: async <T>(): Promise<ApiResponse<T>> => {
    try {
      const { data } = await graphqlInstance.query({
        query: schemaQueries.FETCH_SCHEMA,
        fetchPolicy: "no-cache",
      });
      return data;
    } catch (err: any) {
      console.error(`${PROJECT_CATCH_ERROR_LIST.authoring}:fetchSchema`, err);
      return err?.response?.data || {};
    }
  },
  //end: old apis need to check profile and delte
};
export default contentTypeSchemaApi;
