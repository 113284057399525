import {
  ArticleListIcon,
  BusinessTagIcon,
  Challenge_community,
  CourseListIcon,
  DraftIcon,
  DraftStatusIcon,
  EventsListIcon,
  FormBuilderListIcon,
  General_community,
  News_community,
  PagesListIcon,
  PollListIcon,
  PublishedIcon,
  PublishedStatusIcon,
  QuizListIcon,
  ScheduledUnpublishIcon,
  SchedulePublishIcon,
  SchedulePublishStatusIcon,
  ScheduleUnpublishStatusIcon,
  SpaceListIcon,
  SpacePrivateIcon,
  SpacePublicIcon,
  SystemTagIcon,
  UnpublishedIcon,
  UnpublishedStatusIcon,
  VODListIcon,
} from "@platformx/shared/static-assets";

export const statusIcons: any = {
  draft: DraftIcon,
  published: PublishedIcon,
  unpublished: UnpublishedIcon,
  schedulePublish: SchedulePublishIcon,
  scheduleUnpublish: ScheduledUnpublishIcon,
  public: SpacePublicIcon,
  private: SpacePrivateIcon,
};
export const stateIcons: any = {
  draft: DraftStatusIcon,
  published: PublishedStatusIcon,
  unpublished: UnpublishedStatusIcon,
  schedulePublish: SchedulePublishStatusIcon,
  scheduleUnpublish: ScheduleUnpublishStatusIcon,
};
export const iconsList: any = {
  sitepage: PagesListIcon,
  article: ArticleListIcon,
  quiz: QuizListIcon,
  vod: VODListIcon,
  poll: PollListIcon,
  event: EventsListIcon,
  courses: CourseListIcon,
  news: News_community,
  general: General_community,
  "challenges-announcement": Challenge_community,
  Space: SpaceListIcon,
  SiteTags: BusinessTagIcon,
  SystemTags: SystemTagIcon,
  formbuilder: FormBuilderListIcon,
};

export const SITE_TAGS = "SiteTags";
export const SYSTEM_TAGS = "SystemTags";
