import { Box } from "@mui/material";
import MyStory from "../../../../prelems/MyStory/MyStory";
import { useCustomStyle } from "./MyStoryComponent.style";

const MyStoryComponent = ({ secondaryArgs }) => {
  const classes = useCustomStyle();
  const content = {
    Title: "My Stories",
    PrelemContentType: ["Select"],
  };
  return (
    <Box className={`${classes.myStorySection} myStorySectionBg`}>
      <MyStory content={content} secondaryArgs={secondaryArgs} showTags={true} />
    </Box>
  );
};

export default MyStoryComponent;
