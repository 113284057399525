import { useQuery } from "@apollo/client";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FETCH_DASHBOARD_CHARTS } from "@platformx/authoring-apis";
import { NoSearchResultSvg } from "@platformx/shared/static-assets";
import { ChartSkeltonLoader, ShowToastError, getUserExpSubDomain } from "@platformx/utilities";
import { useTranslation } from "react-i18next";
import { useStyles } from "./Charts.Style";
import "./Charts.css";
import { graph } from "./Constants";
import AreaGraph from "./area/AreaGraph";
import BigNumber from "./area/BigNumber";
import BigNumberTotal from "./area/BigNumberTotal";
import BarChartHorizontal from "./bar/BarChartHorizontal";
import BarChartVertical from "./bar/BarChartVertical";
import StackBarChart from "./bar/StackBarChart";
import MixedChart from "./mixed/MixedChart";
import PieChartWithNeedle from "./pie/PieChartWithneedle";
import PieGraph from "./pie/PieGraph";
import DynamicTable from "./table/DynamicTable";

const Charts = ({ dashboardName, heading, titleVarient }: any) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const loaderCards = [1];
  const dashboardMappingfromEnv = process.env.NX_PUBLIC_CHART_IDS || "";
  const dashboardMapping =
    dashboardMappingfromEnv?.split(",")?.reduce((acc, item) => {
      const obj = item?.split(":") || [];
      const key = obj?.[0];
      const value = obj?.[1];
      if (key && value) return { ...acc, [key]: value };
      else {
        return acc;
      }
    }, {}) || {};
  const mapDashboardIdByName = (name: any) => {
    return dashboardMapping?.[name] || null;
  };
  const { loading, error, data } = useQuery(FETCH_DASHBOARD_CHARTS, {
    variables: {
      dashboardId: `${mapDashboardIdByName(dashboardName)}`,
      siteName: getUserExpSubDomain(),
      environment: process.env.NX_PUBLIC_ELASTIC_APM_ENVIRONMENT,
    },
  });
  const mapGraphType = (graphType: string) => {
    switch (graphType) {
      case graph.chartType.line:
        return graph.LINE;
      case graph.chartType.area:
        return graph.AREA;
      case graph.chartType.bar:
        return graph.BAR;
      case graph.chartType.bartimeseries:
        return graph.BARTIMESERIES;
      case graph.chartType.distbar:
        return graph.DISTBAR;
      case graph.chartType.barhorizontal:
        return graph.BARHORIZONTAL;
      case graph.chartType.pie:
        return graph.PIE;
      case graph.chartType.piewithneedle:
        return graph.PIEWITHNEEDLE;
      case graph.chartType.bignumber:
        return graph.BIGNUMBER;
      case graph.chartType.bignumberTotal:
        return graph.BIGNUMBERTOTAL;
      case graph.chartType.table:
        return graph.TABLE;
      case graph.chartType.mixedtimeseries:
        return graph.MIXEDTIMESERIES;
      default:
        return null;
    }
  };
  const checkDataAvalibility = (item: any) => {
    return item?.chartData &&
      item?.graph_type &&
      item?.chartData.length > 0 &&
      item?.column_names &&
      item?.column_names.length > 0
      ? true
      : false;
  };
  const renderCharts = (item: any) => {
    if (item) {
      const mappedName = mapGraphType(item.graph_type);
      switch (mappedName) {
        case graph.LINE:
          return <AreaGraph itemData={item} />;
        case graph.AREA:
          return <AreaGraph itemData={item} />;
        case graph.BAR:
          return <BarChartHorizontal itemData={item} />;
        case graph.BARTIMESERIES:
          return <BarChartVertical itemData={item} />;
        case graph.BARHORIZONTAL:
          return <BarChartHorizontal itemData={item} />;
        case graph.DISTBAR:
          return item?.meta_data?.bar_stacked
            ? item && <StackBarChart itemData={item} />
            : item && <BarChartVertical itemData={item} />;
        case graph.PIE:
          return <PieGraph itemData={item} />;
        case graph.PIEWITHNEEDLE:
          return <PieChartWithNeedle itemData={item} />;
        case graph.BIGNUMBER:
          return <BigNumber itemData={item} />;
        case graph.BIGNUMBERTOTAL:
          return <BigNumberTotal itemData={item} />;
        case graph.TABLE:
          return <DynamicTable itemData={item} />;
        case graph.MIXEDTIMESERIES:
          return <MixedChart itemData={item} />;
        default:
          return null;
      }
    } else {
      return null;
    }
  };

  return (
    <Grid
      container
      spacing={2}
      className={`${classes.chartsAllContainer} customCharts graphContainerSpacing`}>
      <Typography variant={titleVarient ? titleVarient : "h4bold"} className='chartHeading'>
        {t(heading)}
      </Typography>
      <Grid container className='chartContainer'>
        {loading ? (
          Array.from(loaderCards).map(() => (
            <>
              <Grid item xs={12} md={6} em={6} lg={4} className='chartBox'>
                <Box className='chartSkeltonWrapper'>
                  <ChartSkeltonLoader chartName='pie' />
                </Box>
              </Grid>
              <Grid item xs={12} md={6} em={6} lg={8} className='chartBox'>
                <Box className='chartSkeltonWrapper'>
                  <ChartSkeltonLoader chartName='bar' />
                </Box>
              </Grid>
            </>
          ))
        ) : (
          <>
            {data?.authoring_getDashboardDetailById?.map((item: any) => {
              return checkDataAvalibility(item) ? (
                <Grid
                  item
                  xs={12}
                  md={12}
                  em={item.gridColumn !== 12 ? 6 : 12}
                  lg={item.gridColumn}
                  xl={item.gridColumn}
                  sx={{ marginBottom: "30px" }}
                  key={item?.id}>
                  {renderCharts(item)}
                </Grid>
              ) : null;
            })}
            {((data?.authoring_getDashboardDetailById &&
              data?.authoring_getDashboardDetailById.length === 0) ||
              !data?.authoring_getDashboardDetailById) && (
              <Box className='noDataFound'>
                <img src={NoSearchResultSvg} width={175} height={175} alt='no data found' />
                <Typography variant='h4regular'>{t("no_result_found")}</Typography>
              </Box>
            )}
            {error && ShowToastError(error instanceof Error ? error.message : "An error occurred")}
          </>
        )}
      </Grid>
    </Grid>
  );
};
export default Charts;
