import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    articleDetailPageWrapper: {
      "&.articleDetailPage": {
        "& .articleHeroImage": {
          overflow: "hidden",
        },
        "& .heroTitle": {
          [theme.breakpoints.between("xs", "md")]: {
            width: "auto",
            lineHeight: "1.2",
            overflow: "hidden",
            textOverflow: "ellipsis",
            "-webkit-line-clamp": 2,
            display: "-webkit-box",
            "-webkit-box-orient": "vertical",
            padding: "0px 20px",
          },
          [theme.breakpoints.up("md")]: {
            position: "absolute",
            bottom: "5%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          },
        },
        "& .articleDescription": {
          background: theme.palette.prelemType1.BACKGROUND,
        },
        "& .articleCardDescription": {
          background: theme.palette.prelemType1.BACKGROUND,
          "& .avtartIcon": {
            color: theme.palette.prelemType1.TITLE,
          },
        },
        "& .noMarginTop": {
          marginTop: 0,
        },
        "& .noMarginBottom": {
          marginBottom: 0,
        },
        "& .noMarginBoth": {
          marginBottom: 0,
          marginTop: 0,
        },
      },
    },
  };
});

export default useCustomStyle;
