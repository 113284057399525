import { Skeleton, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

const SkeletonTable = () => {
  const rows = Array.from({ length: 5 });
  return (
    <TableContainer>
      <Table>
        <TableBody>
          {rows.map((_, index) => (
            <TableRow key={index} style={{ height: 70, borderBottom: "1px solid #e0e0e0" }}>
              <TableCell align='left' style={{ padding: "8px 0px 8px 0px", width: "68px" }}>
                <Skeleton variant='rectangular' width={68} height={68} />
              </TableCell>
              <TableCell align='left' style={{ padding: "8px 0px, 8px 0px", width: "40%" }}>
                <Skeleton variant='text' width='90%' height={20} />
              </TableCell>
              <TableCell align='right' style={{ padding: "8px 0px 8px 0px", width: "40%" }}>
                <Skeleton variant='text' width='50%' height={20} />
              </TableCell>
              <TableCell align='right' style={{ padding: "8px 0px 8px 0px", width: "20%" }}>
                <Skeleton variant='text' width='50%' height={37} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default SkeletonTable;
