import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    linearProgressBar: {
      "&.linearProgressBarSection": {
        background: theme.palette.prelemType1.BACKGROUND,
        border: `solid 1px ${theme.palette.prelemType1.LINE}`,
        "& .linearBar": {
          height: "10px",
          borderRadius: theme.borderRadius.value1,
        },
        "& .points": {
          display: "flex",
          "& .subpoints": {
            display: "flex",
            alignItems: "end",
            paddingLeft: "4px",
          },
        },
      },
    },
  };
});
