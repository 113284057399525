import AddIcon from "@mui/icons-material/Add";
import ReplayIcon from "@mui/icons-material/Replay";
import { Box, Grid, TextField, Typography } from "@mui/material";
import {
  // DeleteIcon,
  // Icon,
  ShowToastError,
  getRandomNumberAddon,
  relativeImageURL,
} from "@platformx/utilities";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const Options = ({ showGallery, answers, setAnswers }) => {
  const { t } = useTranslation();
  const [, setOperationType] = useState<string>("replace");
  const [, setAnswers1] = useState<any>(answers);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDeleteDisable, setIsDeleteDisable] = useState(false);

  const handleChange = (event) => {
    if (event.target.value.length > 100) {
      ShowToastError(`${t("max_char")} 100!`);
    }
    setAnswers(
      answers.map((answer) =>
        answer.id === event.target.name ? { ...answer, option: event.target.value } : answer,
      ) as [],
    );
  };
  const onUploadClick = (type, id) => {
    showGallery(type, "answers", id);
    setOperationType(type);
  };
  const onAddOption = () => {
    if (answers?.length < 10) {
      setAnswers([
        ...answers,
        {
          id: `${getRandomNumberAddon(answers, 1, 200)}`,
          option: "",
          image: "",
          status: false,
        },
      ]);
    } else {
      ShowToastError(t("options_limit_toast"));
    }
  };
  useEffect(() => {
    setAnswers1(answers);
  }, [answers]);
  // useEffect(() => {
  //   if (answers.length <= 2) {
  //     setIsDeleteDisable(true);
  //   } else {
  //     setIsDeleteDisable(false);
  //   }
  // }, [answers]);
  const onDeleteOption = (id) => {
    // if (answers.length > 2) {
    setAnswers(answers.filter((item) => item.id !== id));
    // }
  };
  return (
    <>
      {answers.map((answer) => {
        return (
          // eslint-disable-next-line react/jsx-key
          <Grid container className='category-container' sx={{ padding: "5px 5px 5px 0px" }}>
            <Grid
              item
              xs={2.25}
              // sm={1.75}
              sm={1.5}
              sx={{
                display: "flex",
                alignItems: "center", //padding:'0px 5px 0px 5px'
              }}>
              {answer.image ? (
                <Box
                  sx={{
                    width: "48px",
                    height: "48px",
                    // border: "solid 1px #ced3d9",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    position: "relative",
                  }}>
                  <img
                    alt='answer_image'
                    style={{
                      width: "inherit",
                      height: "inherit",
                      objectFit: "cover",
                      borderRadius: "inherit",
                    }}
                    src={relativeImageURL(answer.image)}
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      top: "0",
                      width: "inherit",
                      height: "inherit",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "rgba(0, 0, 0, 0.3)",
                      borderRadius: "inherit",
                    }}>
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() => onUploadClick("Images", answer.id)}>
                        <Box
                          sx={{
                            borderRadius: "50%",
                            width: "25px",
                            height: "25px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: "auto",
                          }}>
                          <ReplayIcon sx={{ color: "#fff" }} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Box
                  onClick={() => onUploadClick("Images", answer.id)}
                  sx={{
                    width: "48px",
                    height: "48px",
                    border: "solid 1px #ced3d9",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}>
                  <img src='' alt='Icon' />
                </Box>
              )}
            </Grid>

            <Grid
              item
              xs={9}
              sm={10.25} //sm={9.25}
              sx={{ padding: "0px 5px 0px 5px" }}>
              <TextField
                error={answer.option?.length > 100}
                variant='filled'
                size='small'
                name={answer.id}
                className='titlefield '
                placeholder={"Reward Details"}
                value={answer?.option}
                inputProps={{ maxLength: 100 }}
                sx={{
                  width: "100%",
                  ".Platform-x-InputBase-root": {
                    paddingRight: "25px !important",
                  },
                }}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={0.75} sm={0.25} sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
                onClick={() => onDeleteOption(answer.id)}>
                <img
                  src=''
                  style={{
                    objectFit: "cover",
                    opacity: isDeleteDisable ? "0.4" : "1",
                  }}
                  alt='Delete Icon'
                />
              </Box>
            </Grid>
          </Grid>
        );
      })}

      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          cursor: "pointer",
          margin: { sm: "10px 10px 10px 0px", xs: "10px 10px 10px -4px" },
          "&:hover": {
            color: "#4B9EF9",
          },
        }}
        onClick={onAddOption}>
        <AddIcon />
        <Typography
          variant='h7regular'
          sx={{
            color: "#2d2d39",
            pl: "8px",
            textTransform: "capitalize",
            "&:hover": {
              color: "#4B9EF9",
            },
          }}>
          Add Reward
        </Typography>
      </Box>
    </>
  );
};
