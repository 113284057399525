import {
  MenuIcon1,
  MenuIcon4,
  MenuIcon5,
  MenuIcon6,
  MenuIcon7,
  VODIcon,
} from "@platformx/shared/static-assets";

export const DEF_VOD = {
  DsapceVideoUrl: "",
  Thumbnail: "",
  title: "",
  description: "",
  Tags: [],
  titleSocialShare: "",
  descriptionSocialShare: "",
  imageSocialShare: "",
  tagsSocialShare: [],
  analytics_enable: true,
  seo_enable: true,
};
export const defaultVodRef = (currentContent) => {
  const {
    CommonFields: {
      title = "",
      tags: Tags = [],
      description = "",
      titleSocialShare = "",
      descriptionSocialShare = "",
      imageSocialShare = "",
      tagsSocialShare = [],
      analytics_enable = true,
      seo_enable = true,
    } = {},
    ObjectFields: { Thumbnail = "", DsapceVideoUrl = "" } = {},
  } = currentContent;
  return {
    title,
    Tags,
    description,
    Thumbnail,
    DsapceVideoUrl,
    analytics_enable,
    seo_enable,
    titleSocialShare,
    descriptionSocialShare,
    imageSocialShare,
    tagsSocialShare,
  };
};
const createVodFlatIcon = [
  {
    id: "titleDescription",
    iconName: MenuIcon1,
    tooltip: "title_description",
  },
  {
    id: "VideoAndThumbnail",
    iconName: VODIcon,
    tooltip: "video_subtitle",
  },
  {
    id: "tags",
    iconName: MenuIcon4,
    tooltip: "choose_tags",
  },
  {
    id: "socialShare",
    iconName: MenuIcon5,
    tooltip: "social_share",
  },
  {
    id: "Analytics",
    iconName: MenuIcon6,
    tooltip: "analytics",
  },
  {
    id: "seo",
    iconName: MenuIcon7,
    tooltip: "seo",
  },
];

export const PATH = "path";
export const DRAFT = "DRAFT";
export const EVENT = "Event";
export const PUBLISHED = "PUBLISHED";
export const IMAGE_URL = "imageURL";
export const IMAGES = "Images";
export const SOCIAL_SHARE_IMG_URL = "socialShareImgURL";
export const SOCIAL_SHARE = "socialShare";
export const CANCEL = "cancel";
export const SEO = "Seo";
export const seo = "seo";
export const SCROLL = "scroll";
export const BEFORE_UNLOAD = "beforeunload";
export const POP_STATE = "popstate";
export const RESIZE = "resize";

export const socialShareTitleSubTitles = (t) => {
  return {
    sectionNumber: "04",
    sectionTitle: t("social_share"),
    sectionSubTitle: t("subhead"),
    imageTitle: t("choose_image"),
    imageSubTitle: t("choose_your_image"),
    mainTitle: t("vod_ss_title"),
    mainSubTitle: t("vod_ss_title_tp"),
    titlePlaceHolder: t("quiz_title_placeholder"),
    descriptionTitle: t("vod_ss_about"),
    descriptionSubTitle: t("vod_ss_about_tp"),
    descriptionPlaceHolder: t("quiz_description_placeholder"),
    tagsTitle: t("quiz_tags_title"),
    tagsSubTitle: t("quiz_tags_subtitle"),
    titleMaxCharLength: 120,
    descriptionMaxCharLength: 400,
    titleFieldName: "titleSocialShare",
    descriptionFieldName: "descriptionSocialShare",
    imageFieldName: "imageSocialShare",
    tagsFieldName: "tagsSocialShare",
  };
};
export const analyticsTitleSubTitles = (t) => {
  return {
    sectionNumber: "05",
    sectionTitle: t("analytics"),
    sectionSubTitle: t("subhead"),
    analyticsTitle: `${t("vod")} ${t("analytics")}`,
    analyticsSubTitle: "",
    impressionTitle: t("impression"),
    impressionSubTitle: "",
    analyticsFieldName: "analytics_enable",
    impressionFieldName: "impression",
  };
};
export const seoTitleSubTitles = (t) => {
  return {
    sectionNumber: "06",
    sectionTitle: t("SEO"),
    sectionSubTitle: t("subhead"),
    seoTitle: t("page_prelem_find"),
    seoSubTitle: t("seo_description"),
    shareTitle: t("page_prelem_share"),
    shareSubTitle: "",
    structureDataTitle: t("page_structure_data"),
    structureDataSubTitle: t("click_to_sd"),
    seoFieldName: "seo_enable",
    shareFieldName: "impression",
    structureDataFieldName: "structure_data",
  };
};
export default createVodFlatIcon;
