import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import { GreenTick } from "@platformx/shared/static-assets";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Confirmation from "../../../Confirmation/Confirmation";
import { getInitials, getTierIcon } from "../../helperProfile";
import EditProfile from "./EditProfile";
import { useCustomStyle } from "./ProfileCard.style";
import ProfileCardSkeleton from "./ProfileCardSkeleton";

const ProfileCard = ({ secondaryArgs, profile, userOlData, setShouldProfileUpdate }) => {
  const [open, setOpen] = useState(false);
  const [successPopUp, setSuccessPopUp] = useState(false);
  const { t } = useTranslation();
  const handleOpen = () => setOpen(true);
  const classes = useCustomStyle();

  const confirmationPopUpClose = () => {
    setSuccessPopUp(false);
  };

  const calculateAge = (birthDate) => {
    const birth = new Date(birthDate);
    const today = new Date();
    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();
    // Adjust age if the birthday hasn't occurred yet this year
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  return (
    <>
      <Box className={`${classes.profileCardWrapper} profileCardWrapperSection`}>
        <Box className='profileCard gap'>
          {!profile?.email && !userOlData?.userOLProfile ? (
            <ProfileCardSkeleton />
          ) : (
            <>
              <Box className='profileCardHighlight'>
                <Button variant='tertiaryButton2' className='profileEdit' onClick={handleOpen}>
                  <ModeEditOutlinedIcon sx={{ width: "16px", height: "16px" }} />
                </Button>
                {/* Profile Image */}
                <Box className='avtarWrapper'>
                  <Avatar src={profile?.image} alt={profile?.first_name} className='profileAvtar'>
                    {getInitials(profile?.first_name, profile?.last_name)}
                  </Avatar>
                  {userOlData && (
                    <Box
                      component='img'
                      src={getTierIcon(userOlData)}
                      alt='Image'
                      className='tireBadge'
                    />
                  )}
                </Box>
                {/* Name */}
                <Typography variant='h4medium' className='profileName tertiaryTitle' mt={2.5}>
                  {profile?.first_name} {profile?.last_name}
                </Typography>
                {/* Age and Gender */}
                <Typography variant='labelregular' className='labelItems tertiaryLabel'>
                  {profile?.dob && `${calculateAge(profile?.dob)} Years,`} {profile?.gender}
                </Typography>
              </Box>
              <Grid container sx={{ marginTop: "30px" }}>
                {/* Row 1 */}
                <Grid item xs={12} sm={6} className='itemsFeature'>
                  <Box className='items'>
                    <Typography variant='labelregular' className='labelItems tertiaryLabel'>
                      {t("first_name1")}
                    </Typography>
                    <Typography variant='p3regular' className='noTopMargin tertiaryParagraph'>
                      {profile?.first_name}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} className='itemsFeature'>
                  <Box className='items'>
                    <Typography variant='labelregular' className='labelItems tertiaryLabel'>
                      {t("last_name")}
                    </Typography>
                    <Typography variant='p3regular' className='noTopMargin tertiaryParagraph'>
                      {profile?.last_name}
                    </Typography>
                  </Box>
                </Grid>

                {/* Row 2 */}

                <Grid item xs={12} sm={12} className='itemsFeature' textAlign={"left"}>
                  <Box>
                    <Typography variant='labelregular' className='labelItems tertiaryLabel'>
                      {t("loyalty_card_no")}
                    </Typography>
                    <Typography variant='p3regular' className='noTopMargin tertiaryParagraph'>
                      {profile?.loyalty_card_number ? profile?.loyalty_card_number : "NA"}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} className='itemsFeature' textAlign={"left"}>
                  <Box>
                    <Typography variant='labelregular' className='labelItems tertiaryLabel'>
                      {t("phone_number")}
                    </Typography>
                    <Typography variant='p3regular' className='noTopMargin tertiaryParagraph'>
                      {profile?.phone}
                    </Typography>
                  </Box>
                </Grid>
                {/* <Grid item xs={12} sm={12} className='itemsFeature' textAlign={"left"}>
                  <Box>
                    <Typography variant='labelregular' className='labelItems tertiaryLabel'>
                      {t("membership_id")}
                    </Typography>
                    <Typography variant='p3regular' className='noTopMargin tertiaryParagraph'>
                      {profile?.member_id ? profile?.member_id : "NA"}
                    </Typography>
                  </Box>
                </Grid> */}
                {/* Row 3 */}
                {/* <Grid item xs={12} sm={6} className='itemsFeature' textAlign={"left"}>
                  <Box>
                    <Typography variant='labelregular' className='labelItems tertiaryLabel'>
                      Phone Number
                    </Typography>
                    <Typography variant='p3regular' className='noTopMargin tertiaryParagraph'>
                      {profile?.phone}
                    </Typography>
                  </Box>
                </Grid> */}
                {/* <Grid item xs={12} sm={6} className='itemsFeature' textAlign={"left"}>
              <Box>
                <Typography variant='labelregular' className='labelItems tertiaryLabel'>
                  Country
                </Typography>
                <Typography variant='p3regular' className='noTopMargin tertiaryParagraph'>
                  {profile?.country ? profile?.country : "NA"}
                </Typography>
              </Box>
            </Grid> */}

                {/* Row 4 */}
                <Grid item xs={12} textAlign={"left"}>
                  <Box>
                    <Typography variant='labelregular' className='labelItems tertiaryLabel'>
                      {t("email_address")}
                    </Typography>
                    <Typography variant='p3regular' className='noTopMargin tertiaryParagraph'>
                      {profile.email}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Box>
      {open && (
        <EditProfile
          secondaryArgs={secondaryArgs}
          setShouldProfileUpdate={setShouldProfileUpdate}
          open={open}
          setSuccessPopUp={setSuccessPopUp}
          handleCloseModel={() => setOpen(false)}></EditProfile>
      )}
      {successPopUp && (
        <Confirmation
          open={true}
          img={GreenTick}
          text={t("profile_update_msg")}
          title={t("profile_update")}
          handleClose={confirmationPopUpClose}
        />
      )}
    </>
  );
};

export default ProfileCard;
