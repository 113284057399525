import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    infoWrapper: {
      "&.infoWrapperSection": {
        width: "100%",
        padding: "24px 0 24px 0",
        background: theme.palette.prelemType1.BACKGROUND,
        "& .headingWrapper": {
          width: "100%",
        },
        "& .paragraphWrapper": {
          maxWidth: "100%",
          [theme.breakpoints.up("lg")]: {
            maxWidth: "768px",
          },
        },
      },
    },
  };
});
