import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  chartsAllContainer: {
    "&.customCharts": {
      "& .chartTable": {
        border: `solid 1px ${theme.palette.customColor.LINE}`,
        borderRadius: theme.borderRadius.value,
        maxWidth: `calc(100% - 18px)`,
        margin: "14px",
        overflowX: "auto",
        "& .iconsWrapper": {
          alignItems: "right",
          display: "flex",
          flexDirection: "column",
          paddingLeft: "10px",
        },
        "& .tableHeadCustomCell": {
          background: theme.palette.customColor.ACCENTS.ACCENT_6,
          fontWeight: "400",
        },
        "& .Platform-x-TableSortLabel-icon": {
          display: "none",
        },
      },
      "& .pageGraph": {
        marginLeft: "16px",
        border: `1px solid ${theme.palette.customColor.LINE}`,
        borderRadius: theme.borderRadius.value,
        padding: "10px 10px 10px 0",
        "& .heading": {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          padding: "0px 14px 0px 14px",
        },
        "& .recharts-tooltip-wrapper .recharts-default-tooltip": {
          backgroundColor: `${theme.palette.customColor.BGLIGHT} !important`,
          border: `1px solid ${theme.palette.customColor.LINE} !important`,
          borderRadius: theme.borderRadius.value1,
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          padding: "10px",
          fontSize: "12px",
          fontWeight: "bold",
        },
      },
    },
  },
}));
