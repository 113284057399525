import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    shareAndGetPoint: {
      "&.shareAndGetPointSection": {
        width: "100%",
        padding: "30px",
        background: theme.palette.prelemType1.ACCENTS.VARIANT6.BACKGROUND,
        "& .shareAndGetPointLeftSection": {
          textAlign: "center",
          [theme.breakpoints.up("em")]: {
            textAlign: "right",
          },
          "& img": {
            maxWidth: "150px",
          },
        },
        "& .shareAndGetPointRightSection": {
          maxWidth: "100%",
          paddingLeft: 0,
          [theme.breakpoints.up("em")]: {
            maxWidth: "80%",
            paddingLeft: "50px",
          },
          "& .copyLink": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            border: `dashed 1px ${theme.palette.prelemType1.ACCENTS.VARIANT6.LINE}`,
            borderRadius: theme.borderRadius.value1,
            marginBottom: 2,
            background: "#F3FAFF",
            maxWidth: "360px",
            "& .couponCode": {
              margin: 0,
              textAlign: "center",
            },
            "& button": {
              margin: 0,
              border: `solid 1px`,
            },
          },
          "& .description": {
            color: theme.palette.prelemType1.ACCENTS.VARIANT6.PARAGRAPH,
          },
          "& .title": {
            color: theme.palette.prelemType1.ACCENTS.VARIANT6.TITLE,
          },
        },
      },
    },
  };
});
