import { Tooltip as MuiTooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import {
  Area,
  AreaChart,
  CartesianGrid,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { graph } from "../Constants";
import { addEllipsis, formatLabel, formatTooltipValue, isTimestampKey } from "../utils/helper";

const AreaGraph = ({ itemData }: any) => {
  const { chartData: data, title } = itemData;
  const config = graph.area;
  const [firstKey] = Object.keys(data?.[0]);
  const isTimestamp = isTimestampKey(firstKey, graph.timeStampFormates);
  const truncateLabel = (label) => {
    if (label?.length > config?.textMaxLength) {
      return addEllipsis(label, config.textMaxLength);
    }
    return label;
  };
  const gradients =
    data?.length > 0 &&
    Object.keys(data[0]).map((key, index) => (
      <linearGradient key={`gradient${key}`} id={`MyGradient${index}`} x1='0' y1='0' x2='0' y2='1'>
        <stop
          offset='5%'
          stopColor={config.graphColor[index % config.graphColor.length]}
          stopOpacity={0.4}
        />
        <stop
          offset='95%'
          stopColor={config.graphColor[index % config.graphColor.length]}
          stopOpacity={0}
        />
      </linearGradient>
    ));

  return (
    <Box className='areaGraph pageGraph'>
      <MuiTooltip title={title} placement='top-start'>
        <Typography variant='h5semibold' className='heading'>
          {title}
        </Typography>
      </MuiTooltip>
      <ResponsiveContainer width={config.width} height={config.height} className='noxyAxsis'>
        <AreaChart data={data} margin={{ top: 20, right: 10, left: -10, bottom: 42 }}>
          {config.showGrid && <CartesianGrid strokeDasharray='3 3' />}
          {isTimestamp ? (
            <XAxis
              dataKey={firstKey}
              interval={0}
              textAnchor='end'
              angle={config.textXAngle}
              // scale="time"
              tickFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()}
              tick={{ fontSize: config.fontSize, fill: config.textColor }}
            />
          ) : (
            <XAxis
              dataKey={firstKey}
              interval={0}
              textAnchor='end'
              angle={config.textXAngle}
              tick={{ fontSize: config.fontSize, fill: config.textColor }}
              tickFormatter={(label) => truncateLabel(label)}
            />
          )}
          <YAxis
            tick={{ fontSize: config.fontSize, fill: config.textColor }}
            tickFormatter={(label) => formatLabel(label)}
          />
          {isTimestamp ? (
            <Tooltip labelFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()} />
          ) : (
            <Tooltip formatter={formatTooltipValue} />
          )}
          {config.showLegend && (
            <Legend
              verticalAlign={config.legendPosition as any}
              height={46}
              iconType={config?.iconType as any}
            />
          )}
          <defs>{gradients}</defs>
          {data?.length > 0 &&
            Object.keys(data[0]).map((key, index) => {
              if (key !== firstKey) {
                return (
                  <Area
                    key={key}
                    type={config.type as any}
                    dataKey={key}
                    stackId={index}
                    stroke={config.graphColor[index % config.graphColor.length]}
                    strokeWidth={config.strokeWidth}
                    fill={`url(#MyGradient${index})`}>
                    {config.showValuesOnTop && (
                      <LabelList
                        dataKey={key}
                        name={key}
                        position='top'
                        fontSize={config.fontSize}
                        fill={config.graphColor[index % config.graphColor.length]}
                      />
                    )}
                  </Area>
                );
              }
              return null;
            })}
        </AreaChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default AreaGraph;
