import { Box, Skeleton } from "@mui/material";

const RadialChartskelton = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          width: "300px", // Outer circle size
          height: "300px",
          borderRadius: "50%",
          backgroundColor: "transparent",
          margin: "0px auto",
        }}>
        {/* Outer circle with border */}
        <Box
          sx={{
            position: "absolute",
            width: 200,
            height: 200,
            borderRadius: "50%",
            border: "10px solid rgba(0, 0, 0, 0.1)", // Adjust color and opacity as needed
            backgroundColor: "transparent",
          }}
        />
        {/* First inner circle with border */}
        <Box
          sx={{
            position: "absolute",
            width: 150,
            height: 150,
            borderRadius: "50%",
            border: "10px solid rgba(0, 0, 0, 0.1)",
            backgroundColor: "transparent",
          }}
        />
        {/* Second inner circle with border */}
        <Box
          sx={{
            position: "absolute",
            width: 100,
            height: 100,
            borderRadius: "50%",
            border: "10px solid rgba(0, 0, 0, 0.1)",
            backgroundColor: "transparent",
          }}
        />
        {/* Innermost circle with border */}
        <Box
          sx={{
            position: "absolute",
            width: 50,
            height: 50,
            borderRadius: "50%",
            border: "10px solid rgba(0, 0, 0, 0.1)",
            backgroundColor: "transparent",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            width: 25,
            height: 25,
            borderRadius: "50%",
            border: "10px solid rgba(0, 0, 0, 0.1)",
            backgroundColor: "transparent",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          position: "relative",
          bottom: 0,
          padding: "0 10px",
        }}>
        {[...Array(4)].map((_, index) => (
          <Box
            key={index}
            sx={{
              width: "calc(25% - 10px)", // Adjust for spacing
              height: 75,
              border: "1px solid rgba(0, 0, 0, 0.1)",
              borderRadius: "4px",
              padding: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <Skeleton variant='text' width='100%' height={20} />
            <Skeleton variant='text' width='100%' height={16} />
          </Box>
        ))}
      </Box>
    </>
  );
};

export default RadialChartskelton;
