import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { SharePoint } from "@platformx/shared/static-assets";
import { useState } from "react";
import { useCustomStyle } from "./ShareAndGetPoint.style";

const ShareAndGetPoint = ({ referalCode }) => {
  const classes = useCustomStyle();
  const [buttonText, setButtonText] = useState("Copy Code"); // Initial button text

  const handleCopy = () => {
    navigator?.clipboard
      ?.writeText(referalCode)
      .then(() => {
        setButtonText("Copied!"); // Change the text when copy is successful

        // Reset the button text after 2 seconds (optional)
        setTimeout(() => {
          setButtonText("Copy Code");
        }, 2000);
      })
      .catch(() => {
        setButtonText("Failed to Copy"); // Handle error case
        // Reset the button text after 2 seconds (optional)
        setTimeout(() => {
          setButtonText("Copy Code");
        }, 5000);
      });
  };
  return (
    <Box className={`${classes.shareAndGetPoint} shareAndGetPointSection`}>
      <Grid container spacing={2} alignItems='center'>
        {/* First Column: Image */}
        <Grid item xs={12} md={5} className='shareAndGetPointLeftSection'>
          <img src={SharePoint} alt='Referral' style={{ width: "100%", height: "auto" }} />
        </Grid>
        {/* Second Column: Content */}
        <Grid item xs={12} md={7}>
          <Box className='shareAndGetPointRightSection'>
            <Typography variant='h4medium' className='noBottomMargin title'>
              Share and get 300 Points
            </Typography>
            <Typography variant='p4regular' className='description'>
              Refer your Friend and get 200 Loyalty points each on their first transaction.
            </Typography>
            {/* Coupon Code and Copy Button Section */}
            {referalCode ? (
              <Box className='copyLink'>
                <Typography
                  variant='h6semibold'
                  component='span'
                  className='couponCode description'>
                  {referalCode}
                </Typography>

                {/* Copy Code Button (aligned to the right) */}
                <Button variant='primaryButton2' onClick={handleCopy}>
                  {buttonText}
                </Button>
              </Box>
            ) : null}
            {/* Share Via Section */}
            <Box display='flex' mb={1}>
              <Typography variant='p4regular' className='description'>
                Share Via:
              </Typography>
              <IconButton sx={{ width: "40px", height: "40px" }}>
                <FacebookIcon />
              </IconButton>
              <IconButton sx={{ width: "40px", height: "40px" }}>
                <LinkedInIcon />
              </IconButton>
              <IconButton sx={{ width: "40px", height: "40px" }}>
                <TwitterIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShareAndGetPoint;
