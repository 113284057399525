import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowRounded";
import { Box, Skeleton } from "@mui/material";
import { formRelativeURL } from "@platformx/utilities";
import axios from "axios";
import { memo, useEffect, useState } from "react";
import { useCustomStyle } from "./VideoCard.style";

const VideoCard = ({ secondaryArgs }) => {
  const classes = useCustomStyle();
  const [vodContent, setVodContent] = useState<any>(null);
  const [isLoading, setLoading] = useState(true);
  const {
    prelemBaseEndpoint: { deliveryEndPointGq = "" } = {},
    sitename,
    globalSettings,
  } = secondaryArgs || {};
  let promoContent: any = null;
  if (globalSettings?.promo_content) {
    promoContent = JSON?.parse(globalSettings?.promo_content)?.[0] || null;
  }

  const getVODData = async () => {
    try {
      const input = {
        pagePath: promoContent?.Id,
        contentType: "VOD",
      };
      const response = await axios.post(
        deliveryEndPointGq,
        {
          query: `
      query FETCH_CONTENT_DETAIL($pagePath: String!, $contentType: publish_ContentTypes!) {
        publish_contentDetail(pagePath: $pagePath, contentType: $contentType)
      }
    `,
          variables: input,
        },
        {
          headers: {
            site_host: sitename,
            "Content-Type": "application/json",
          },
        },
      );
      const { data: { data: { publish_contentDetail = {} } = {} } = {} } = response;
      setVodContent(publish_contentDetail);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setVodContent(null);
    }
  };

  const navigateToVOD = () => {
    window.open(
      `/${secondaryArgs?.prelemBaseEndpoint?.language || "en"}/video/${vodContent?.page}`,
    );
  };
  useEffect(() => {
    if (!promoContent?.Id || vodContent !== null) {
      setLoading(false);
      return;
    }
    getVODData();
  }, [promoContent]);

  return (
    <Box className={`${classes.videoCardWrapper} videoCardWrapperSection`}>
      {isLoading ? (
        <Skeleton variant='rectangular' sx={{ width: "100%", height: "100%" }} />
      ) : !isLoading && vodContent ? (
        <Box
          sx={{ cursor: "pointer", height: "inherit", width: "inherit" }}
          onClick={navigateToVOD}>
          <img
            src={formRelativeURL(
              secondaryArgs?.gcpUrl,
              secondaryArgs?.bucketName,
              vodContent?.Thumbnail,
            )}
            alt='promo content'
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
          <Box className='playIcon'>
            <PlayArrowRoundedIcon
              data-testid='play-vod-enable'
              style={{
                background: "white",
                borderRadius: "100px",
                color: "black",
                padding: "6px",
                cursor: "pointer",
              }}
              sx={{
                fontSize: { xs: "44px", sm: "44px" },
              }}
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default memo(VideoCard);
