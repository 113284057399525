import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    profileTabItem: {
      "&.profileTabSection": {
        background: theme.palette.prelemType1.BACKGROUND,
        "& .leftSection": {
          paddingRight: "8px",
          [theme.breakpoints.down("lg")]: {
            paddingRight: 0,
            marginBottom: "16px",
          },
        },
        "& .rightSection": {
          paddingLeft: "8px",
          [theme.breakpoints.down("lg")]: {
            paddingLeft: 0,
          },
        },
        "& .Loyaltywrapper": {
          background: "linear-gradient(149.61deg, #7165EB -4.6%, #908FEC 100%)",
          borderRadius: theme.borderRadius.value1,
          marginBottom: "16px",
          "& .loyaltyProgressBarSection": {
            width: "100%",
          },
        },
      },
    },
  };
});
