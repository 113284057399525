import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    achivementWrapper: {
      "&.achivementSection": {
        background: "linear-gradient(149.61deg, #7165EB -4.6%, #908FEC 100%)",
        // "& .achivementWrapper": {
        [theme.breakpoints.down("em")]: {
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          "& .achivement": {
            width: "50%",
            borderRight: "1px solid #5D5B9E",
          },
        },
        // },
      },
    },
  };
});
