import {
  getCartId,
  getLocationStorage,
  getPipedData,
  getPipedFullAddress,
  getRpiInstance,
  getTotalQuantity,
  prepareObjectForRPI,
} from "./helper";
import {
  ADD_TO_CART,
  BUTTON_CLICK,
  CART_CHECKOUT,
  CHANNEL,
  ECOM,
  MY_STORIES_FEED,
  PAYMENT_DETAIAL,
  PRODUCT_VIEW,
  RPI_TAG_SELECTION,
  UPDATE_PROFILE,
  USER_PROFILE_UPDATE,
} from "./trackingConstants";

/**
 * Generates a default event object with common properties for tracking.
 *
 * @returns {Object} The default event object containing common tracking properties.
 */
const getdefaultEventObj = (pageName, secondaryArgs) => {
  const geolocationDataString = getLocationStorage();
  let geolocationData;
  if (geolocationDataString) {
    geolocationData = JSON.parse(geolocationDataString);
  }
  // eslint-disable-next-line no-console
  console.log("geolocationData", geolocationData);
  const { prelemBaseEndpoint: { environment = "", language = "en", site_host = "" } = {} } =
    secondaryArgs;

  return {
    page_name: pageName,
    page_url: window?.location?.href,
    language: language,
    created_datetime: new Date().toISOString(),
    env_name: environment,
    site_host: site_host,
    card_list: "",
    channel: CHANNEL,
    // userId: getUserId(),
    // device_type: getDeviceType(),
    // device_browser: getBrowser(),
    // device_os: getDeviceOS(),
    // country: geolocationData?.country_name,
    // city: geolocationData?.city,
    // ip: geolocationData?.ip,
  };
};

/**
 * Tracks the "Product View" event.
 *
 * @param {Object} card - The product card object containing product details.
 */
export const trackProductView = (card, secondaryArgs) => {
  const { id, ecomx_name, ecomx_sale_price, ecomx_attributes_brand } = card;
  const template = {
    action: PRODUCT_VIEW?.eventName,
    content_type: ECOM,
    product_id: id,
    product_name: ecomx_name,
    product_price_per_unit: parseFloat(ecomx_sale_price)?.toFixed(2) ?? 0,
    product_category: ecomx_attributes_brand,
    // currency: ecomx_currency_code,
    // quantity: 1,
    // total_price: ecomx_sale_price,
    ...getdefaultEventObj("product-detail", secondaryArgs),
  };

  const eventDetail = {
    ...PRODUCT_VIEW,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail); // Track the event
};

/**
 * Tracks the "Add to Cart" event.
 *
 * @param {Object} card - The product card object containing product details.
 * @param {string} containerType - The type of container where the event occurred.
 */
export const trackAddToCart = (data, secondaryArgs) => {
  const {
    id,
    ecomx_name,
    ecomx_sale_price,
    ecomx_currency_code,
    ecomx_attributes_brand,
    cartQuantity = 1,
  } = data;
  const template = {
    action: ADD_TO_CART?.eventName,
    cart_id: getCartId(),
    content_type: ECOM,
    product_id: id,
    product_name: ecomx_name,
    product_quantity: cartQuantity ? cartQuantity : 1,
    product_price_per_unit: parseFloat(ecomx_sale_price)?.toFixed(2) ?? 0,
    currency: ecomx_currency_code,
    total_value: parseFloat((ecomx_sale_price * cartQuantity)?.toFixed(2)) ?? 0,
    product_category: ecomx_attributes_brand,
    ...getdefaultEventObj("product-detail", secondaryArgs),
  };

  const eventDetail = {
    ...ADD_TO_CART,
    stateValue: cartQuantity,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail); // Track the event
};

/**
 * Tracks the "Checkout" event.
 *
 * @param {Object} card - The cart object containing checkout details.
 */
export const trackCheckout = (card, secondaryArgs) => {
  const { line_item, total_price, currency_code } = card;
  const template = {
    action: CART_CHECKOUT?.eventName,
    cart_id: getCartId(),
    content_type: ECOM,
    cart_total_items: getTotalQuantity(line_item) || 0,
    cart_total_amount: parseFloat(total_price)?.toFixed(2) ?? 0,
    currency: currency_code,
    ...getdefaultEventObj("cart-list", secondaryArgs),
  };

  const eventDetail = {
    ...CART_CHECKOUT,
    stateValue: card?.line_item?.length || 0,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail); // Track the event
};

export const trackPaymentDetail = (card, secondaryArgs) => {
  const {
    line_items,
    shipping_address,
    billing_address,
    paymentMethod,
    order_id,
    currency_code,
    total_price,
  } = card;
  const template = {
    action: PAYMENT_DETAIAL?.eventName,
    cart_id: getCartId(),
    content_type: ECOM,
    transaction_id: "",
    order_id: order_id,
    payment_method: paymentMethod,
    shipping_address: getPipedFullAddress(shipping_address),
    shipping_email: shipping_address?.email,
    shipping_phone_number: shipping_address?.mobile,
    billing_address: getPipedFullAddress(billing_address),
    billing_email: billing_address?.email,
    billing_phone_number: billing_address?.contactNumber,
    // shipping_address: getFullAddress(card),
    // billing_aaddress: getFullAddress(card),
    cart_total_items: getTotalQuantity(line_items),
    shipping_cost: 0,
    estimated_tax: 0,
    total_amount: parseFloat(total_price).toFixed(2) ?? 0,
    currency: currency_code,
    discount_amount: "",
    discount_coupon: "",
    ...getdefaultEventObj("payment", secondaryArgs),
  };

  const eventDetail = {
    ...PAYMENT_DETAIAL,
    stateValue: card?.line_item?.length || 0,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail); // Track the event
};

//Tag selection tracking
export const trackRPITagSelection = (data, secondaryArgs) => {
  const { container_type, tags } = data;
  const template = {
    action: BUTTON_CLICK,
    button_name: MY_STORIES_FEED,
    container_type: container_type,
    my_story_preferences: getPipedData(tags),
    destination_url: "",
    ...getdefaultEventObj(container_type, secondaryArgs),
  };

  const eventDetail = {
    ...RPI_TAG_SELECTION,
    stateValue: 1,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail); // Track the event
};

//User save profile - rendering
export const trackUserProfileSaveRendering = (data, secondaryArgs) => {
  const { container_type, pageName } = data;
  const template = {
    action: BUTTON_CLICK,
    button_name: UPDATE_PROFILE,
    container_type: container_type,
    destination_url: "",
    ...getdefaultEventObj(pageName, secondaryArgs),
  };

  const eventDetail = {
    ...USER_PROFILE_UPDATE,
    stateValue: 1,
    data: prepareObjectForRPI(template),
  };
  trackImpression(eventDetail); // Track the event
};

/**
 * Sends the event details to the RPI (Real-time Processing Interface) for tracking.
 *
 * @param {Object} eventDetail - The event detail object containing event information.
 */
const trackImpression = (eventDetail) => {
  if (getRpiInstance()) {
    globalThis.rpiWebClient.pushWebEvent(
      eventDetail?.eventName, // Event name
      eventDetail.stateValue, // Quantity or other relevant parameter
      eventDetail?.extraParam, // Event description
      eventDetail?.contentId, // Optional content ID or other identifier
      eventDetail?.data, // Pass the event data object
    );
  }
};
