import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  const theme = useTheme();
  return {
    orderHighlights: {
      "&.orderHighlightsWrapper": {
        background: theme.palette.prelemType1.BACKGROUND,
        border: `solid 1px ${theme.palette.prelemType1.LINE}`,
        marginTop: "32px",
        marginBottom: "32px",
        padding: "10px 20px",
        "& .seprator": {
          [theme.breakpoints.up("md")]: {
            borderRight: `solid 1px ${theme.palette.prelemType1.LINE}`,
            marginRight: "16px",
            height: `calc(100% - 16px)`,
          },
        },
        "& .gap": {
          marginBottom: "8px",
          marginTop: "8px",
        },
        "& .noPadding": {
          padding: 0,
        },
        "& .highlightValue": {
          wordBreak: "break-word",
          width: "90%",
        },
      },
    },
  };
});
