import { Avatar, Box, Skeleton } from "@mui/material";

const ProfileCardSkeleton = () => {
  return (
    <Box display='flex' flexDirection='column' alignItems='center' className='customSkeleton'>
      {/* Center Avatar */}
      <Avatar sx={{ width: 98, height: 98, marginBottom: 2, marginTop: 2 }}>
        <Skeleton variant='circular' width={98} height={98} />
      </Avatar>

      {/* Center Heading */}
      <Skeleton variant='text' width={150} height={30} sx={{ marginBottom: 2 }} />

      {/* Two Blocks */}
      {Array.from({ length: 6 }).map((_, index) => (
        <Box display='flex' justifyContent='space-between' width='100%' mb={2} key={index}>
          {/* Left Block */}
          <Box width='45%'>
            <Skeleton variant='text' width='100%' height={20} />
            <Skeleton variant='text' width='100%' height={10} />
          </Box>

          {/* Right Block */}
          <Box width='45%'>
            <Skeleton variant='text' width='100%' height={20} />
            <Skeleton variant='text' width='100%' height={10} />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default ProfileCardSkeleton;
