/* eslint-disable no-unused-vars */
import { Box } from "@mui/material";
import { AutoCompleteText, AutoTextArea, TextBox, TitleSubTitle } from "@platformx/utilities";
import { XImageRender } from "@platformx/x-image-render";
import { useTranslation } from "react-i18next";
import { useStyles } from "./SocialShare.styles";

function SocialShare({ state, setState, socialOgTags, setSocialOgTags }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleOnBlur = (event) => {
    setState(
      {
        ...state,
        CommonFields: {
          ...state.CommonFields,
          settings: {
            ...state.CommonFields.settings,
            [event.target.name]: event.target.value,
          },
        },
      } || {},
    );
  };

  const uploadSocialImage = (data) => {
    const relativeUrl = `${data?.original_image.original_image_relative_path}.${data?.original_image.ext}`;

    setState({
      ...state,
      CommonFields: {
        ...state.CommonFields,
        settings: {
          ...state.CommonFields.settings,
          socialog_image: relativeUrl,
        },
      },
    });
  };

  return (
    <>
      <Box className={classes.container}>
        <TitleSubTitle
          title={t("select_an_image")}
          //subTitle={t("choose_your_image")}
          titleVariant='h6medium'
          subTitleVariant='p4regular'
        />
      </Box>
      <XImageRender
        callBack={uploadSocialImage}
        editData={{
          relativeUrl: state.CommonFields.settings.socialog_image,
        }}
      />
      <Box className={classes.container}>
        <TitleSubTitle
          title={t("article_seo_title")}
          // subTitle={t("quiz_ss_subtitle")}
          titleVariant='h6medium'
          subTitleVariant='p4regular'
        />
      </Box>
      <TextBox
        name='socialog_title'
        placeHolder={t("quiz_title_placeholder")}
        handleOnBlur={handleOnBlur}
        maxCharLength={120}
        state={(state?.CommonFields?.settings?.socialog_title || "").substring(0, 120)}
      />
      <Box className={classes.container}>
        <TitleSubTitle
          title={t("article_seo_about")}
          // subTitle={t("quiz_ss_subdescription")}
          titleVariant='h6medium'
          subTitleVariant='p4regular'
        />
      </Box>
      <AutoTextArea
        name='socialog_description'
        placeHolder={t("quiz_description_placeholder")}
        handleOnBlur={handleOnBlur}
        maxCharLength={400}
        state={state?.CommonFields?.settings?.socialog_description || ""}
      />
      <Box className={classes.container}>
        <TitleSubTitle
          title={t("quiz_tags_title")}
          // subTitle={t("quiz_tags_subtitle")}
          titleVariant='h6medium'
          subTitleVariant='p4regular'
        />
      </Box>
      <AutoCompleteText socialShareInfo={socialOgTags} setSocialShareInfo={setSocialOgTags} />
    </>
  );
}

export default SocialShare;
