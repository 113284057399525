import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useEffect, useState } from "react";
import { dynamicAPICall } from "../../../utils/helperFns";
import HiddenTextBox from "../HiddenTextBox/HiddenTextBox";

export const Dropdown = ({
  options,
  state,
  setState,
  error,
  register,
  field,
  clearErrors,
  callbackEvents,
}: any) => {
  const handleChange = (event: SelectChangeEvent) => {
    setState({ ...state, [field?.name]: event.target.value });
    clearErrors(field?.name);
    if (field?.events) {
      callbackEvents(event, field?.events);
    }
  };

  const [optionsList, setOptionsList] = useState(options || []);
  const [defaultValue, setDefaultValue] = useState("");

  useEffect(() => {
    // Extract onload event and query
    const onloadGraphqlEvent = field?.events?.find(
      (x) => x.event === "onload" && x.type === "graphql",
    );
    if (onloadGraphqlEvent) {
      const { query = "", responseVariable = "" } = onloadGraphqlEvent?.query || {};
      if (query && responseVariable) {
        dynamicAPICall(query, responseVariable).then((result) => {
          setOptionsList(result || []);
        });
      }
    }
  }, []);

  useEffect(() => {
    setOptionsList(options);
  }, [options]);

  useEffect(() => {
    if (optionsList?.length > 0) {
      let defValue =
        optionsList.find(
          (x) => x?.label === state?.[field.name]?.[0]?.label || x?.label === state?.[field?.name],
        ) || null;
      if (defValue) {
        setDefaultValue(defValue?.label);
      } else {
        defValue =
          optionsList.find(
            (x) => x?.label === field?.default_value?.[0] || x?.label === field?.name,
          ) || null;
        setDefaultValue(defValue?.label);
      }
    }
  }, [state[field.name], optionsList]);

  return (
    <Box id={`${field?.name}_id`}>
      <FormControl fullWidth error={error}>
        <Select
          labelId='demo-simple-select-label'
          id='demo-simple-select'
          value={defaultValue}
          name={field?.name}
          variant='filled'
          error={error}
          onChange={handleChange}>
          {optionsList?.map((item, index) => {
            return (
              <MenuItem
                key={index}
                value={item?.label}
                disabled={item?.disabled ? item?.disabled || false : false}>
                {item?.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <HiddenTextBox
        error={error}
        value={state?.[field?.name] || ""}
        register={register}
        name={field?.name}
        required={
          field?.validations.length > 0 &&
          field?.validations[0]?.type === "required" &&
          state?.[field?.name] === ""
            ? field?.validations[0]?.message
            : ""
        }
      />
    </Box>
  );
};
