import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RadialBar, RadialBarChart, ResponsiveContainer, Tooltip } from "recharts";
import { getPointsBreakout } from "../../helperProfile";
import { useCustomStyle } from "./RadialChart.style";
import RadialChartskelton from "./RadialChartskelton";

// Define an array of colors
const colors = [
  "#00429A", // Dark Blue
  "#3282FA", // Light Sky Blue
  "#00C8F6", // Very Light Blue
  "#00C8F6", // Very Light Blue (duplicate)
  "#005FB0", // Medium Blue
  "#0074B0", // Bright Blue
  "#008BD0", // Light Blue
  "#0F5FDC", // Sky Blue
  "#3282FA", // Light Sky Blue
  "#7CD8FB", // Pale Blue
  "#4577C1", // Medium Light Blue
];

interface CustomTooltipProps {
  active?: boolean;
  payload?: any[];
  t: (key: string) => string;
}

const CustomTooltip = ({ active, payload, t }: CustomTooltipProps) => {
  if (active && payload?.length) {
    return payload[0]?.payload?.event ? (
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "4px",
          padding: "10px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        }}>
        <Typography variant='body2' fontWeight='bold'>
          {t("event")}: {payload[0]?.payload?.event}
        </Typography>
        <Typography variant='body2'>
          {t("point")}: {payload[0]?.payload?.pointsEarned}
        </Typography>
      </Box>
    ) : null;
  }
  return null;
};

const RadialChart = ({ height, secondaryArgs, userTotalEarnedPoints, profile }) => {
  const classes = useCustomStyle();
  const { t } = useTranslation();
  const [pointsData, setPointsData] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  const processEvents = (events) => {
    const itemShown = 4;
    if (events.length > itemShown) {
      const pointEarned = events
        .slice(itemShown)
        .reduce((sum, event) => sum + event.pointsEarned, 0);
      const totalEventCount = events
        .slice(itemShown)
        .reduce((sum, event) => sum + event.totalEventCount, 0);
      const othersEvent = {
        event: "Others",
        pointsEarned: pointEarned,
        pointsDeducted: 0,
        totalEventCount: totalEventCount,
        value: pointEarned,
        fill: colors[itemShown + (1 % colors.length)],
      };
      return [...events.slice(0, itemShown), othersEvent];
    }
    return events;
  };
  const getUserOLProfileAPI = async () => {
    try {
      const res = await getPointsBreakout({
        secondaryArgs: secondaryArgs,
        userEmail: profile?.email,
      });
      const { data: { data: { users_fetchMemberTransactions = {} } = {} } = {} } = res;
      if (users_fetchMemberTransactions?.length) {
        const dataWithColors = users_fetchMemberTransactions?.map((item, index) => ({
          ...item,
          value: item.pointsEarned,
          fill: colors[index % colors.length],
        }));
        const addedOthers = processEvents(dataWithColors);
        const addOuterCircle = {
          event: "",
          pointsEarned: userTotalEarnedPoints,
          value: userTotalEarnedPoints,
          fill: "#fff",
        };
        const radialData = [
          ...addedOthers,
          ...(addedOthers.length !== 1 ? [addOuterCircle] : []),
        ].sort((a, b) => a.pointsEarned - b.pointsEarned);
        setPointsData(radialData);
        setLoading(false);
      } else {
        setPointsData([]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching pointsBreakout:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (userTotalEarnedPoints >= 0 && profile?.member_id) {
      getUserOLProfileAPI();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTotalEarnedPoints, profile?.member_id]);

  return (
    <Box className={`${classes.radialCharts} radialChartsSection`}>
      <Box className='radialChartWrapper'>
        <Typography variant='h4medium' className='noMargin'>
          {t("my_points_breakout")}
        </Typography>
        {loading ? (
          <RadialChartskelton />
        ) : pointsData?.length > 0 ? (
          <>
            <Box>
              <ResponsiveContainer width={"100%"} height={height}>
                <RadialBarChart
                  cx='50%'
                  cy='50%'
                  innerRadius={pointsData?.length === 1 ? "70%" : "20%"}
                  outerRadius='100%'
                  barSize={10}
                  startAngle={90} // Start from the top
                  endAngle={-270} // Fill in clockwise direction
                  data={pointsData}>
                  <RadialBar
                    startAngle={180}
                    endAngle={360}
                    background
                    dataKey='value'
                    minPointSize={1}
                  />
                  <Tooltip content={<CustomTooltip t={t} />} />
                </RadialBarChart>
              </ResponsiveContainer>
            </Box>
            <Box className='radialChartTextWrapper'>
              {pointsData
                ?.slice()
                ?.sort((a, b) => b.pointsEarned - a.pointsEarned)
                ?.map((item) => {
                  if (
                    item?.pointsEarned !== userTotalEarnedPoints ||
                    (pointsData.length === 1 && item?.pointsEarned === userTotalEarnedPoints)
                  ) {
                    return (
                      <Box key={item?.event} className='events'>
                        <Typography
                          variant='p4regular'
                          className='noMargin ellipsis-two-lines labelcolor'>
                          {item.event}
                        </Typography>
                        <Typography component='span' variant='h4bold' className='noMargin points'>
                          {item.pointsEarned}
                          <Typography
                            component='span'
                            variant='h6bold'
                            className='noMargin subpoints'>
                            PTS
                          </Typography>
                        </Typography>
                      </Box>
                    );
                  }
                  return null;
                })}
            </Box>
          </>
        ) : (
          <Box className='noresultFound'>
            <Typography variant='h5regular' className='message'>
              {`${t("no_results_found")}`}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RadialChart;
