// import useTheme from "@mui/material/styles/useTheme";
import { makeStyles } from "@mui/styles";

export const useCustomStyle = makeStyles(() => {
  // const theme = useTheme();
  return {
    loyaltyProgressBar: {
      "&.loyaltyProgressBarSection": {
        width: "100%",
        background: `rgba(191, 188, 255, 0.46)`,
        // padding: "10px",
        borderRadius: "8px",
        border: `solid 1px #fff`,
        height: "90px",
      },
    },
  };
});
